import { CreatureDefinitionType } from "../types/creatureDefinitionType";

export const CreaturesConst : CreatureDefinitionType[] = [
    {
        id: '5488379f-76ec-426a-8d27-e33a59ea4854',
        url: '/images/gooblies/gablok.jpg',
        rarity: 10,
        category: 1,
        name: 'Gablok',
        tags: ["Monstre mignon", "Aventure loufoque", "Ami des insectes"],
        type: 'NORMAL',
        level: 10
    },    
    {
        id: '35f22c5f-a3a7-431b-9181-2e516175c37e',
        url: '/images/gooblies/dirtzy.jpg',
        rarity: 1,
        category: 1,
        name: 'Dirtzy',
        tags: ["Monstre de la boue","Ami des tortues","Amuseur des marécages"],
        description: 'Once uppon a time',
        type: 'NORMAL',
        level: 6
    },    
    {
        id: '940708f3-2ab0-41c2-b604-e1a865071ed0',
        url: '/images/gooblies/gromol.jpg',
        rarity: 2,
        category: 1,
        name: 'Gromol',
        tags: ["Monstre gourmand","Amoureux des bonbons", "Grignoteur de sucreries"],
        description: 'Once uppon a time',
        type: 'LEGENDARY',
        level: 36
    },    
    {
        id: '1ff7f51d-f129-40c1-839b-4dd39eb3a003',
        url: '/images/gooblies/puddy.jpg',
        rarity: 1,
        category: 1,
        name: 'Puddy',
        tags: ["Monstre des bois", "Explorateur timide", "Gardien des secrets"],
        description: 'Once uppon a time',
        type: 'NORMAL',
        level: 12
    },    
    {
        id: 'bf374df4-4397-4493-83a6-db33d958d9e3',
        url: '/images/gooblies/waldoo.jpg',
        rarity: 3,
        category: 1,
        name: 'Waldoo',
        tags: ["Monstre tacheté", "Aventurier des jardins","Chercheur de trésors"],
        description: 'Once uppon a time',
        type: 'NORMAL',
        level: 8
    },   
    {
        id: '88de64ab-34f5-4eff-8d6a-2ed481316abc',
        url: '/images/gooblies/rostok.jpg',
        rarity: 5,
        category: 1,
        name: 'Rostok',
        tags: ["Monstre des profondeurs", "Gardien des secrets aquatiques","Amis des créatures marines"],
        description: 'Once uppon a time',
        type: 'LEGENDARY',
        level: 66
    },   
    {
        id: '072e1e1d-5b29-4bb0-8c9f-9f1283b7df3b',
        url: '/images/gooblies/glorp.jpg',
        rarity: 5,
        category: 1,
        name: 'Glorp',
        tags: ["Monstre plumeux", "Rêveur curieux", "Ami des oiseaux"],
        description: 'Once uppon a time',
        type: 'NORMAL',
        level: 21
    },   
    {
        id: '3fcbcf4d-a05e-49bb-9b63-ecc606e87a77',
        url: '/images/gooblies/mukzy.jpg',
        rarity: 9,
        category: 1,
        name: 'Mukzy',
        tags: ["Monstre mélancolique","Solitaire au grand cœur","Créateur d'objets magiques"],
        description: 'Once uppon a time',
        type: 'NORMAL',
        level: 9
    },  
    {
        id: 'a48edb20-7fa2-4c91-b53c-4a283eaa3992',
        url: '/images/gooblies/splork.jpg',
        rarity: 2,
        category: 1,
        name: 'Splork',
        tags: ["Monstre gluant","Habitants des cavernes","Protecteur des marécages"],
        description: 'Once uppon a time',
        type: 'LEGENDARY',
        level: 76
    },  
    {
        id: '169ac202-f7a7-4fb2-aedc-d3003c1e25f7',
        url: '/images/gooblies/slobzer.jpg',
        rarity: 2,
        category: 1,
        name: 'Slobzer',
        tags: ["Monstre géant affamé","Gardien de la savane","Ami des explorateurs"],
        description: 'Once uppon a time',
        type: 'LEGENDARY',
        level: 82
    },  
    {
        id: '76c34ac3-5c4e-4673-8375-c55b1592554f',
        url: '/images/gooblies/gimzy.jpg',
        rarity: 2,
        category: 1,
        name: 'Gimzy',
        tags: ["Monstre espiègle","Ami des crabes","Joueur des ruelles inondées"],
        description: 'Once uppon a time',
        type: 'NORMAL',
        level: 36
    },  
    {
        id: 'cb18da51-e60d-4666-af64-e4f51c9dbf7f',
        url: '/images/gooblies/mirty.jpg',
        rarity: 2,
        category: 1,
        name: 'Mirty',
        tags:["Monstre coloré","Explorateur des temples","Collectionneur de fleurs"],
        description: 'Once uppon a time',
        type: 'NORMAL',
        level: 9
    },
    {
        id: '47f77d6e-f48e-4c8d-806c-d865b15fe3ae',
        url: '/images/gooblies/slorb.jpg',
        rarity: 8,
        category: 1,
        name: 'Slorb',
        tags: ["Monstre joueur","Constructeur créatif","Amateur de briques"],
        description: 'Once uppon a time',
        type: 'NORMAL',
        level: 23
    },
    {
        id: '4dc37175-a54b-45f7-82c5-9dfe957592de',
        url: '/images/gooblies/fluzer.jpg',
        rarity: 3,
        category: 1,
        name: 'Fluzer',
        tags: ["Monstre cyclope","Espiègle nocturne","Gardien des secrets"],
        description: 'Once uppon a time',
        type: 'NORMAL',
        level: 10
    },
    {
        id: '2ed7f532-48fc-4126-aee2-663360405cd6',
        url: '/images/gooblies/smorvy.jpg',
        rarity: 4,
        category: 1,
        name: 'Smorvy',
        description: 'Once uppon a time',
        type: 'NORMAL',
        level: 3
    },
    {
        id: 'b1315317-55c3-4fd6-a1c2-bec1456a0e8c',
        url: '/images/gooblies/marloo.jpg',
        rarity: 7,
        category: 1,
        name: 'Marloo',
        tags: ["Monstre gluissant","Maître des potions","Ami des blobs"],
        description: 'Once uppon a time',
        type: 'NORMAL',
        level: 15
    },
    {
        id: '49be1a73-48b0-467f-b363-d1361d61bcc5',
        url: '/images/gooblies/bozor.jpg',
        rarity: 2,
        category: 1,
        name: 'Bozor',
        tags: ["Monstre bricoleur","Inventeur génial","Maître des machines"],
        description: 'Once uppon a time',
        type: 'LEGENDARY',
        level: 86
    },
    {
        id: '2c4a4bd3-8372-4908-8d81-158956a56d3b',
        url: '/images/gooblies/grogny.jpg',
        rarity: 2,
        category: 1,
        name: 'Grogny',
        tags:["Monstre grognon","Protecteur des jouets","Gardien du coffre"],
        description: 'Once uppon a time',
        type: 'NORMAL',
        level: 34
    },
    {
        id: 'e47e4547-95b3-4208-85d5-c5b7000259f9',
        url: '/images/gooblies/florak.jpg',
        rarity: 1,
        category: 1,
        name: 'Florak',
        tags: ["Monstre jardinier","Protecteur des plantes","Ami des cactus"],
        description: 'Once uppon a time',
        type: 'NORMAL',
        level: 11
    },
    {
        id: '8f24139e-864d-4036-98e0-bedabae32b56',
        url: '/images/gooblies/baldur.jpg',
        rarity: 2,
        category: 1,
        name: 'Baldur',
        tags: ["Monstre farceur","Chasseur d'ombres","Roi des déserts"],
        description: 'Once uppon a time',
        type: 'NORMAL',
        level: 15
    },
    {
        id: 'b5c9f361-7abb-4281-8884-9ff4a23ef1c4',
        url: '/images/gooblies/crookzy.jpg',
        rarity: 6,
        category: 1,
        name: 'Crookzy',
        tags: ["Monstre malicieux","Roi des insectes","Collectionneur de gadgets"],
        description: 'Once uppon a time',
        type: 'LEGENDARY',
        level: 8
    },
    {
        id: 'fb8662d0-4d45-4f15-b387-9ba89a51aaa4',
        url: '/images/gooblies/roomly.jpg',
        rarity: 3,
        category: 1,
        name: 'Roomly',
        tags: ["Monstre artiste","Peintre créatif","Maître des couleurs"],
        description: 'Once uppon a time',
        type: 'NORMAL',
        level: 17
    },
    {
        id: '081b3531-1f3d-4a1d-9339-3a2c3a95c047',
        url: '/images/gooblies/seelvy.jpg',
        rarity: 4,
        category: 1,
        name: 'Seelvy',
        tags: ["Monstre peintre","Artiste chaotique","Créateur de couleurs"],
        description: 'Once uppon a time',
        type: 'NORMAL',
        level: 10
    },
    {
        id: 'edc2d073-e126-4ba2-a15e-24daac9aff38',
        url: '/images/gooblies/grimlak.jpg',
        rarity: 2,
        category: 1,
        name: 'Grimlak',
        tags: ["Monstre des marécages","Gardien visqueux","Ami des créatures aquatiques"],
        description: 'Once uppon a time',
        type: 'LEGENDARY',
        level: 33
    },
    {
        id: 'c9ff897e-b654-48df-8868-90d98d80c3ae',
        url: '/images/gooblies/sozy.jpg',
        rarity: 2,
        category: 1,
        name: 'Sozy',
        tags: ["Monstre marin","Gardien des océans","Protecteur des plages"],
        description: 'Once uppon a time',
        type: 'NORMAL',
        level: 28
    },
    {
        id: '60a411ee-403e-464d-8acb-5fd7af936601',
        url: '/images/gooblies/boonzy.jpg',
        rarity: 1,
        category: 1,
        name: 'Boonzy',
        tags: ["Monstre écolier", "Curieux et espiègle", "Ami des crayons"],
        description: 'Once uppon a time',
        type: 'NORMAL',
        level: 2
    },
    {
        id: 'c90349c3-eff3-499a-af43-6a839674449e',
        url: '/images/gooblies/kafy.jpg',
        rarity: 5,
        category: 1,
        name: 'Kafy',
        tags: ["Monstre matinal","Amateur de café","Monstre grognon du réveil"],
        description: 'Once uppon a time',
        type: 'NORMAL',
        level: 4
    },
    {
        id: 'c8dfb1f9-9b48-4c6b-952b-89b71838e69f',
        url: '/images/gooblies/gary.jpg',
        rarity: 2,
        category: 1,
        name: 'Gary',
        tags: ["Monstre géant nomade","Gardien des caravanes","Voyageur des déserts"],
        description: 'Once uppon a time',
        type: 'LEGENDARY',
        level: 24
    },


    {
        id: 'cfb266f4-68e5-4224-852c-eb3f45ca99d0',
        url: '/images/gooblies/blublu.jpg',
        rarity: 2,
        category: 1,
        name: 'Blublu',
        description: 'Once uppon a time',
        type: 'AQUATIC',
        level: 56
    },
    {
        id: 'b2d57d18-9b98-4218-8973-f14eabeaeb02',
        url: '/images/gooblies/gazor.jpg',
        rarity: 1,
        category: 1,
        name: 'Gazor',
        tags: ["Monstre des profondeurs","Gardien des récifs","Explorateur sous-marin"],
        description: 'Once uppon a time',
        type: 'AQUATIC',
        level: 32
    },
    {
        id: 'c246df82-5115-4900-9a0f-b1fae7168d7b',
        url: '/images/gooblies/biloo.jpg',
        rarity: 2,
        category: 1,
        name: 'Biloo',
        tags: ["Monstre des jardins", "Ami des insectes","Gardien des champignons"],
        description: 'Once uppon a time',
        type: 'AQUATIC',
        level: 17
    },
    {
        id: '92ec165e-1a0d-435a-9e3b-5af789f90c15',
        url: '/images/gooblies/salazar.jpg',
        rarity: 10,
        category: 1,
        name: 'Salazar',
        tags: ["Monstre marin","Protecteur des récifs","Explorateur des profondeurs"],
        description: 'Once uppon a time',
        type: 'AQUATIC',
        level: 27
    },
    {
        id: 'c746a9b3-2d53-4c8f-9651-5131c28fad8e',
        url: '/images/gooblies/olgar.jpg',
        rarity: 3,
        category: 1,
        name: 'Olgar',
        tags: ["Monstre aquatique","Gardien des rivières","Explorateur de bassins"],
        description: 'Once uppon a time',
        type: 'AQUATIC',
        level: 40
    },
    {
        id: '87610d99-9e77-48a0-bfb9-acb257279af2',
        url: '/images/gooblies/gluppy.jpg',
        rarity: 2,
        category: 1,
        name: 'Gluppy',
        tags: ["Monstre joyeux","Amoureux des bulles","Gardien des fonds marins"],
        description: 'Once uppon a time',
        type: 'AQUATIC',
        level: 37
    },
    {
        id: '25d0ee15-d071-4b1b-8cf1-fabe98299131',
        url: '/images/gooblies/cezar.jpg',
        rarity: 4,
        category: 1,
        name: 'Cezar',
        tags: ["Monstre des abysses","Roi des poissons","Protecteur des coraux"],
        description: 'Once uppon a time',
        type: 'AQUATIC',
        level: 28
    },
    {
        id: '8f34a0f2-8702-4529-874c-df1e9e01440a',
        url: '/images/gooblies/grazoo.jpg',
        rarity: 1,
        category: 1,
        name: 'Grazoo',
        tags: ["Monstre militaire","Protecteur des océans","Soldat des mers"],
        description: 'Once uppon a time',
        type: 'AQUATIC',
        level: 72
    },
    {
        id: 'a239ed93-90b8-418b-8d37-0a873569662a',
        url: '/images/gooblies/bob.jpg',
        rarity: 2,
        category: 1,
        name: 'Bob',
        tags: ["Monstre des sables","Explorateur curieux","Protecteur des coraux"],
        description: 'Once uppon a time',
        type: 'AQUATIC',
        level: 11
    },
    {
        id: 'ba72df83-b54b-4a1d-950f-b6edfcef1ff9',
        url: '/images/gooblies/oorzy.jpg',
        rarity: 1,
        category: 1,
        name: 'Oorzy',
        tags: ["Monstre gluant","Gardien des marais","Amoureux des flaques"],
        description: 'Once uppon a time',
        type: 'AQUATIC',
        level: 22
    },
    {
        id: 'd01cca04-4430-479c-9ada-c74afb71b4ce',
        url: '/images/gooblies/gilbert.jpg',
        rarity: 3,
        category: 1,
        name: 'Gilbert',
        tags: ["Monstre cyclope","Gardien des rivages","Explorateur de grottes"],
        description: 'Once uppon a time',
        type: 'LEGENDARY',
        level: 26
    },
    {
        id: '97e88561-eff6-4200-a84e-087ec0ca72a4',
        url: '/images/gooblies/gromain.jpg',
        rarity: 5,
        category: 1,
        name: 'Gromain',
        tags: ["Monstre cyclope","Gardien des plantes","Amoureux des fleurs"],
        description: 'Once uppon a time',
        type: 'NORMAL',
        level: 15
    },
    {
        id: 'da6adcd1-9ef0-4ec0-aef8-22b3ce651384',
        url: '/images/gooblies/nasdan.jpg',
        rarity: 4,
        category: 1,
        name: 'Nasdan',
        tags: ["Monstre des marécages","Cyclope boueux","Gardien des terres humides"],
        description: 'Once uppon a time',
        type: 'NORMAL',
        level: 14
    },    
    {
        id: '91423ad2-6f1f-4ed9-8e32-4221f72797df',
        url: '/images/gooblies/mirok.jpg',
        rarity: 2,
        category: 1,
        name: 'Mirok',
        tags: ["Monstre cyclope","Gardien des jouets","Protecteur des miniatures"],
        description: 'Once uppon a time',
        type: 'NORMAL',
        level: 11
    },
    {
        id: '1c92fef4-7849-4080-872b-a4202bc1c6a9',
        url: '/images/gooblies/glabys.jpg',
        rarity: 9,
        category: 1,
        name: 'Glabys',
        tags: ["Monstre des marécages","Cyclope coloré","Protecteur des créatures aquatiques"],
        description: 'Once uppon a time',
        type: 'NORMAL',
        level: 13
    },
    {
        id: '1a7561d5-f4f3-418c-aa75-71f601a4736c',
        url: '/images/gooblies/trovlik.jpg',
        rarity: 3,
        category: 1,
        name: 'Trovlik',
        tags: ["Monstre de la forêt","Veilleur de la nuit","Créature lunaire"],
        description: 'Once uppon a time',
        type: 'NORMAL',
        level: 42
    },
    {
        id: '09e47728-2657-41d0-9f90-83d71707d33e',
        url: '/images/gooblies/dimzer.jpg',
        rarity: 5,
        category: 1,
        name: 'Dimzer',
        tags: ["Monstre de la cave","Mangeur de jouets","Gardien du désordre"],
        description: 'Once uppon a time',
        type: 'LEGENDARY',
        level: 51
    },
    {
        id: '8e99c702-2d7d-4d03-9252-68eefd327ea2',
        url: '/images/gooblies/roloz.jpg',
        rarity: 2,
        category: 1,
        name: 'Roloz',
        tags: ["Monstre des citrouilles","Gardien des récoltes","Collectionneur de crânes"],
        description: 'Once uppon a time',
        type: 'NORMAL',
        level: 32
    },
    {
        id: '275963bf-3e75-42db-b6f4-7b636ba56171',
        url: '/images/gooblies/bizoo.jpg',
        rarity: 1,
        category: 1,
        name: 'Bizoo',
        tags: ["Monstre bibliothécaire","Gardien des livres","Collectionneur de jouets"],
        description: 'Once uppon a time',
        type: 'NORMAL',
        level: 8
    },
    {
        id: '06f361ed-3f4d-4e00-8be7-6e4f41c05ccb',
        url: '/images/gooblies/zirak.jpg',
        rarity: 2,
        category: 1,
        name: 'Zirak',
        tags: ["Monstre triste","Ami sous la pluie","Cornes boueuses"],
        description: 'Once uppon a time',
        type: 'AQUATIC',
        level: 9
    },
    {
        id: '5846a663-b219-4305-a19e-08eac025f4ae',
        url: '/images/gooblies/poozy.jpg',
        rarity: 3,
        category: 1,
        name: 'Poozy',
        tags: ["Monstre sous la pluie","Parapluie trempé","Grosse chaussure boueuse"],
        description: 'Once uppon a time',
        type: 'AQUATIC',
        level: 10
    },
    {
        id: '3cbce59f-2791-4105-8ed8-bde9be0cc5ef',
        url: '/images/gooblies/glaglar.jpg',
        rarity: 4,
        category: 1,
        name: 'Glaglar',
        tags: ["Monstre des neiges","Flocon sur la tête","Insecte amusant"],
        description: 'Once uppon a time',
        type: 'ICE',
        level: 5
    },
    {
        id: '3d5534da-6920-4122-b553-13e3d6839b34',
        url: '/images/gooblies/balador.jpg',
        rarity: 3,
        category: 1,
        name: 'Balador',
        tags: ["Monstre de la forêt","Montre antique","Champignons sur la tête"],
        description: 'Once uppon a time',
        type: 'ICE',
        level: 21
    },
    {
        id: '651d3ed1-0c34-4c5c-8c73-253529edc6c1',
        url: '/images/gooblies/cornak.jpg',
        rarity: 2,
        category: 1,
        name: 'Cornak',
        tags: ["Monstre des montagnes","Écailles rugueuses","Regard étonné"],
        description: 'Once uppon a time',
        type: 'ICE',
        level: 18
    },
    {
        id: '8a06a299-5961-4452-87d8-7f45341f19fd',
        url: '/images/gooblies/mimi.jpg',
        rarity: 2,
        category: 1,
        name: 'Mimi',
        tags: ["Petit monstre de la terre","Peluche câline","Ami des enfants"],
        description: 'Once uppon a time',
        type: 'NORMAL',
        level: 3
    },
    {
        id: '1d4f9037-11d1-4024-984f-8bed0f94b276',
        url: '/images/gooblies/lizzy.jpg',
        rarity: 7,
        category: 1,
        name: 'Lizzy',
        tags: ["Monstre licorne","Peluche magique","Ami des étoiles"],
        description: 'Once uppon a time',
        type: 'NORMAL',
        level: 7
    },
    {
        id: '84b2c964-f193-4755-81ba-f717bc443e6a',
        url: '/images/gooblies/sarak.jpg',
        rarity: 1,
        category: 1,
        name: 'Sarak',
        tags: ["Monstre rose","Créature sucrée","Langue bleue"],
        description: 'Once uppon a time',
        type: 'NORMAL',
        level: 6
    },
    {
        id: 'a4b23270-6ac8-47f0-b3bc-680d2320b331',
        url: '/images/gooblies/moly.jpg',
        rarity: 4,
        category: 1,
        name: 'Moly',
        tags: ["Monstre cyclope","Peluche câline","Coeur rose"],
        description: 'Once uppon a time',
        type: 'NORMAL',
        level: 9
    },
    {
        id: 'b31a10f6-77a4-415c-ae84-671792601210',
        url: '/images/gooblies/lorzy.jpg',
        rarity: 2,
        category: 1,
        name: 'Lorzy',
        tags: ["Monstre cyclope","Fourrure bleue et violette","Cornes oranges"],
        description: 'Once uppon a time',
        type: 'NORMAL',
        level: 13
    },
    {
        id: '59e9cdad-390d-4568-8798-9f8e36f20d9a',
        url: '/images/gooblies/marly.jpg',
        rarity: 3,
        category: 1,
        name: 'Marly',
        tags: ["Monstre aux grands yeux","Fourrure verte","Nœud rose à pois"],
        description: 'Once uppon a time',
        type: 'NORMAL',
        level: 8
    },
    {
        id: 'f3592fc9-cd58-4433-94ff-a0da9262c2cc',
        url: '/images/gooblies/galby.jpg',
        rarity: 6,
        category: 1,
        name: 'Galby',
        tags: ["Monstre cyclope","Nœud rose à pois","Corps vert avec des boutons rouges"],
        description: 'Once uppon a time',
        type: 'NORMAL',
        level: 2
    },
    {
        id: '60fcc1a8-6c3b-4f8d-9752-29d0cc07f0ad',
        url: '/images/gooblies/soroz.jpg',
        rarity: 2,
        category: 1,
        name: 'Soroz',
        tags: ["Monstre bleu à poils","Bonnet rose avec une fleur","Cornes beige"],
        description: 'Once uppon a time',
        type: 'NORMAL',
        level: 14
    },
    {
        id: 'a2c4608c-09a8-4151-844b-1348a2f9c565',
        url: '/images/gooblies/gigy.jpg',
        rarity: 3,
        category: 1,
        name: 'Gigy',
        tags: ["Monstre bleu avec des piquants","Grand nœud rose sur la tête","Boue et bonbons"],
        description: 'Once uppon a time',
        type: 'NORMAL',
        level: 7
    },
    {
        id: '4cde1405-b7db-467f-a5bc-7f9e4378e241',
        url: '/images/gooblies/charly.jpg',
        rarity: 7,
        category: 1,
        name: 'Charly',
        tags: ["Monstre avec moustache","Chapeau melon","Tenue élégante avec bretelles"],
        description: 'Once uppon a time',
        type: 'NORMAL',
        level: 24
    },
    {
        id: '6a415b8c-e3a0-43a5-ac41-af31d663720c',
        url: '/images/gooblies/yvan.jpg',
        rarity: 2,
        category: 1,
        name: 'Yvan',
        tags: ["Monstre avec longue langue","Vêtements de bûcheron","Seau rempli de globes oculaires"],
        description: 'Once uppon a time',
        type: 'NORMAL',
        level: 8
    },
    {
        id: '949af2e7-8ee2-405c-b28e-541e07bd145e',
        url: '/images/gooblies/alby.jpg',
        rarity: 3,
        category: 1,
        name: 'Alby',
        tags: ["Monstre avec cheveux roses","Gros yeux fatigués","Accessoires de coiffure"],
        description: 'Once uppon a time',
        type: 'NORMAL',
        level: 33
    },
    {
        id: '53f4c141-2018-4803-8bec-19663c98d8d3',
        url: '/images/gooblies/zirmy.jpg',
        rarity: 2,
        category: 1,
        name: 'Zirmy',
        tags: ["Monstre poilu et désordonné","Gros yeux expressifs","Arc rose sur la tête"],
        description: 'Once uppon a time',
        type: 'NORMAL',
        level: 10
    },
    {
        id: 'f1ed9a56-dfe4-4f36-b179-05cebac5b161',
        url: '/images/gooblies/rory.jpg',
        rarity: 3,
        category: 1,
        name: 'Rory',
        tags: ["Monstre rose","Pelage coloré","Monstre créatif"],
        description: 'Once uppon a time',
        type: 'NORMAL',
        level: 16
    },
    {
        id: '6e3b63d4-c58b-4b51-ad0d-883d53456796',
        url: '/images/gooblies/mirly.jpg',
        rarity: 2,
        category: 1,
        name: 'Mirly',
        tags: ["Petit monstre bleu","Nœud rose à pois","Aventure en forêt"],
        description: 'Once uppon a time',
        type: 'NORMAL',
        level: 3
    },
    {
        id: '59b6725d-f9d2-46c9-8361-f008279509d6',
        url: '/images/gooblies/lozly.jpg',
        rarity: 4,
        category: 1,
        name: 'Lozly',
        tags: ["Monstre joyeux","Gâteau à la crème","Nœud rose à pois"],
        description: 'Once uppon a time',
        type: 'NORMAL',
        level: 12
    },
    {
        id: '90071ecd-153b-4c0b-ac81-8189426964f3',
        url: '/images/gooblies/marlok.jpg',
        rarity: 2,
        category: 1,
        name: 'Marlok',
        tags: ["Peluche triste","Forêt mystique","Cornes roses"],
        description: 'Once uppon a time',
        type: 'NORMAL',
        level: 8
    },
    {
        id: 'eeef8440-d085-48d8-a72b-e8d63238f3fe',
        url: '/images/gooblies/loozak.jpg',
        rarity: 1,
        category: 1,
        name: 'Loozak',
        tags: ["Monstre rose","Cornes courbées","Boue collante"],
        description: 'Once uppon a time',
        type: 'NORMAL',
        level: 7
    },


    {
        id: '1e702006-5b15-422e-a4c5-914156e6fbe9',
        url: '/images/gooblies/grozy.jpg',
        rarity: 3,
        category: 1,
        name: 'Grozy',
        tags: ["Espiègle", "Ténébreux", "Aventurier"],
        description: 'Once uppon a time',
        type: 'FLYING',
        level: 24
    },
    {
        id: '8cad070a-4a70-4ef3-873b-0f59879b5ab0',
        url: '/images/gooblies/frisdo.jpg',
        rarity: 2,
        category: 1,
        name: 'Frisdo',
        tags: ["Intrépide", "Energique", "Combattant"],
        description: 'Once uppon a time',
        type: 'FLYING',
        level: 18
    },
    {
        id: '796e545c-647f-44f3-a7ce-37ea6e60028d',
        url: '/images/gooblies/galox.jpg',
        rarity: 1,
        category: 1,
        name: 'Galox',
        tags: ["Mécanique", "Espiègle", "Bricoleur"],
        description: 'Once uppon a time',
        type: 'FLYING',
        level: 11
    },
    {
        id: '865e18b4-e7ae-45cd-9885-56e3f920b5d3',
        url: '/images/gooblies/smartok.jpg',
        rarity: 1,
        category: 1,
        name: 'Smartok',
        tags: ["Visqueux", "Curieux", "Malicieux"],
        description: 'Once uppon a time',
        type: 'FLYING',
        level: 5
    },
    {
        id: '0b53d2b4-9fa6-452f-a2cb-6a1038c729a8',
        url: '/images/gooblies/zoobi.jpg',
        rarity: 1,
        category: 1,
        name: 'Zoobi',
        tags: ["Joueur", "Espiègle", "Fluffy"],
        description: 'Once uppon a time',
        type: 'FLYING',
        level: 11
    },
    {
        id: 'aec50e56-048e-4f01-88b5-bbee6347a7d3',
        url: '/images/gooblies/zortax.jpg',
        rarity: 3,
        category: 1,
        name: 'Zortax',
        tags: ["Flamboyant", "Protecteur", "Aérien"],
        description: 'Once uppon a time',
        type: 'FLYING',
        level: 27
    },
    {
        id: 'ddd0743d-6b1b-4cc5-ba2d-fa944127b4ae',
        url: '/images/gooblies/griboo.jpg',
        rarity: 4,
        category: 1,
        name: 'Griboo',
        tags: ["Malicieux", "Curieux", "Artiste"],
        description: 'Once uppon a time',
        type: 'FLYING',
        level: 5
    },
    {
        id: 'ece6af18-ce63-439a-843f-cc73f088f096',
        url: '/images/gooblies/wizy.jpg',
        rarity: 3,
        category: 1,
        name: 'Wizy',
        tags: ["Mystérieux", "Sage", "Magique"],
        description: 'Once uppon a time',
        type: 'FLYING',
        level: 6
    },
    {
        id: 'd01f3b50-0309-423d-8626-9626515a6e90',
        url: '/images/gooblies/zadox.jpg',
        rarity: 2,
        category: 1,
        name: 'Zadox',
        tags: ["Réfléchi", "Mélancolique", "Ingénieux"],
        description: 'Once uppon a time',
        type: 'FLYING',
        level: 27
    },
    {
        id: '7397f8e7-5cc3-4934-9dd8-c2eadcdc3cb3',
        url: '/images/gooblies/zirglo.jpg',
        rarity: 4,
        category: 1,
        name: 'Zirglo',
        tags: ["Serein", "Angélique", "Protecteur"],
        description: 'Once uppon a time',
        type: 'FLYING',
        level: 21
    },
] 
