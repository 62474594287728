import { FaFeather } from "react-icons/fa6";
import {IoIosWater} from "react-icons/io";
import { UnblockedType } from "../types/unblockedType";

export const UnblockedConst : UnblockedType[] = [
    {
        id: "a64bd0f0-039b-436d-87b0-f0b95b6950f3",
        name: "feather",
        text: {
            en: "Feather",
            fr: "Plume",
            de: "Feder",
            nl: "Veertje",
        },
        url: "/images/feather.jpg",
        unblockText: {
            fr: "Vous avez débloqué les Gooblies volants",
            en: "You have unlocked the flying Gooblies",
            de: "Sie haben die fliegenden Gooblies freigeschaltet",
            nl: "Je hebt de vliegende Gooblies ontgrendeld",
        },
        icon: <FaFeather />
    }
] 
