import { useContext } from "react";
import { Button, Text, Flex, Input, Skeleton } from "@chakra-ui/react";
import { useTranslation } from "next-i18next";
import { useRouter } from "next/router";
import { AuthenticationContext } from "@/app/context/authentication/authenticationContext";
import { PaymentContext } from "@/app/context/payment/paymentContext";
import { buildApiStripeCheckouUrl } from "@/app/helpers/buildUrlHelper";
import { ContentPriceType } from "../type/contentPriceType";
import { WebviewType } from "@/app/types/webviewType";
import { GlobalDataContext } from "../../global/globalDataContext";

type Props = {
    categoryId?:number, 
    content: ContentPriceType
}

export const ButtonPaymentNativeCheckout = ({categoryId, content }:Props) => {
    const router = useRouter();
    const { language } = router.query;
    const {t} = useTranslation();

    const {
        webviewPending,
        setWebviewPending,
        setWebviewTransactionSuccess
    } = useContext(GlobalDataContext)
    const {
        user
    } = useContext(AuthenticationContext)

    const {
        setErrorPayment
    } = useContext(PaymentContext)

    const handleCheckout=async()=>{
        if (window.ReactNativeWebView) {
            setWebviewPending(true)
            setWebviewTransactionSuccess(null)
            const data:WebviewType= {
                domain: "GOOBLIES",
                action: "purchase",
                payload: {
                    contentId: content.providerId.reactNative,
                }
            }
            window.ReactNativeWebView.postMessage(JSON.stringify(data));
        }
    }

    return(
        <>
            <Flex
                flexDirection={"column"}
            >
                 <Skeleton isLoaded={!webviewPending}>
                    <Button
                        type={"submit"}
                        background="#f16529!important" 
                        color="white!important" 
                        m={0}
                        ml={3}
                        onClick={handleCheckout}
                    >
                        {t("payment.doPay")} : {content.price} €
                    </Button>            
                </Skeleton>
            </Flex>
        </>
    )
}
