
import { User } from "firebase/auth";
import {
    or,
    updateDoc,
    collection,
    where,
    doc,
    Firestore,
    getDoc,
    getDocs,
    onSnapshot,
    query,
    setDoc,
    writeBatch,
    runTransaction,
    and,
    deleteDoc,
    limit,
    orderBy
} from "firebase/firestore";
import CloudStorageInterface from "../interface/CloudStorageInterface";
import { FriendRequestType } from "../context/friends/types/friendRequestType";
import { ConfigType } from "../types/configType";
import { ScoreType } from "../types/scoreType";
import { v4 } from "uuid";
import { ZoneType } from "../types/zoneType";
import { MyCreaturesType } from "../types/myCreaturesType";
import { AnaDiscussionStatusType } from "../context/notification/type/anaDiscussionStatusType";
import { calculateBoundingBox, getDistance } from "./mapHelper";
import { Dispatch, SetStateAction } from "react";
import { UserStateType } from "../types/userStateType";
import { ActionHistoryType } from "../context/global/type/actionHistoryType";
import { UserInfoType } from "../types/userInfoType";
import { TradeRequestType } from "../types/tradeRequestType";
import { last } from "lodash";
import { PaymentType } from "../context/payment/type/paymentType";
import stripeToPaidHelper from "./stripeToPaidHelper";
import { MyItemsType } from "../types/myItemsType";
/*import { AnaUser } from "../interface/anaUserInterface";
import { ConfigType } from "../types/configType";
import { ScoreType } from "../types/scoreType";
import CloudStorageInterface from "../interface/CloudStorageInterface";
import { NoUserException } from "../exceptions/noUserException";
import { UserAnonymousException } from "../exceptions/userAnonymousException";
import { ContactType } from "../types/contactType";
import { ContextType } from "../types/contextType";
import { ContextPipeType } from "../types/contextPipeType";
import { Dispatch, SetStateAction } from "react";
import { PaidContentType } from "../types/paidContentType";
import stripeToPaidHelper from "./stripeToPaidHelper";
import { FriendRequestType } from "../types/friendRequestType";
import { v4 } from "uuid";
import { ObjectType } from "../types/objectType";
import { MapType } from "../types/mapType";
*/

import {getDatabase, ref, set, onValue, query as queryDB, off, limitToFirst, startAt, orderByChild, endAt, get} from "firebase/database";
import { PlayersType } from "../types/playersType";
import { distanceBetween, geohashForLocation, geohashQueryBounds } from 'geofire-common';
import { MyArtifactType } from "../types/myArtifactType";



class CloudStorageFirestoreHelper implements CloudStorageInterface {

    private user: User
    private db: Firestore

    constructor(user: User, db: Firestore) {
        this.checkUser(user)
        this.user = user
        this.db = db
    }

    fixAllToIsSynchronized = (array:any[])=>{
        return array.map((item)=>{
            return {...item, isSynchronized:true}
        })
    }

    checkUser = (user: User)=>{
        if ( !user || user==null){
            throw new Error("Not connected")
        }
        if (user && user.isAnonymous ){
            throw new Error("Anonymous")
        }
        return true
    }

    getUserInfo = async (userId?:string):Promise<any|null> => {
        try {
            this.checkUser(this.user)
        }catch(e){
            return null
        }
        userId = userId??this.user?.uid
        const data = await getDoc(doc(this.db as Firestore, `/users/${userId}`))
        if ( process.env.NODE_ENV==="development" ) {
            console.log("CloudStorageFirestoreHelper getUserInfo", data)
        }
        if (data.exists()) {
            return data.data();
        } 
        return false /*{
            id: userId,
            userName: "",
            photoUrl: "",
            token: ""

        }*/
        
    }

    setUserInfoToCloud= async(userInfo:UserInfoType)=> {
        try {
            this.checkUser(this.user)
        }catch(e){
            return
        }
        
        try {
            if ( process.env.NODE_ENV==="development" ) {
                console.log("CloudStorageFirestoreHelper setUserInfoToCloud",userInfo)
            }
            const docRef = doc(this.db as Firestore, `/users`, `${this.user?.uid}`)
            await setDoc(docRef, {
                ...userInfo,
                id: this.user?.uid,
            }, { merge: true });
            //await Promise.all(contacts.map((data) => collectionRef.doc(data.id.toString()).set(data)));
        } catch (error) {
            if ( process.env.NODE_ENV==="development" ) {
                console.error('Erreur lors de la mise à jour de la collection :', error);
            }
        }
        return
        
    }

    updateLastActivityToCloud= async()=> {
        try {
            this.checkUser(this.user)
        }catch(e){
            return
        }
        
        try {
            if ( process.env.NODE_ENV==="development" ) {
                console.log("CloudStorageFirestoreHelper updateLastActivityToCloud")
            }
            const docRef = doc(this.db as Firestore, `/users`, `${this.user?.uid}`)
            await setDoc(docRef, {
                lastActivity: new Date().getTime()
            }, { merge: true });
            //await Promise.all(contacts.map((data) => collectionRef.doc(data.id.toString()).set(data)));
        } catch (error) {
            if ( process.env.NODE_ENV==="development" ) {
                console.error('Erreur lors de la mise à jour de la collection :', error);
            }
        }
        return
        
    }

    isUsernameAvailable = async (username: string) => {
        const userCollection = collection(this.db as Firestore, 'users');
        const q = query(userCollection, where('userName', '==', username),where('id', '!=', this.user?.uid));
        const querySnapshot = await getDocs(q);
    
        if (querySnapshot.empty) {
            return true;
        } else {
            return false;
        }
      };
    
      generateSuggestions = async (baseUsername: string) => {
        
        const suggestedUsernames = [];
        const maxLength = 20;
      
        // Détermine la longueur maximale que le baseUsername peut avoir
        // pour ajouter un nombre aléatoire tout en restant sous la limite de 20 caractères
        const maxBaseLength = maxLength - 4; // Réserve 4 caractères pour un nombre aléatoire (jusqu'à 9999)
      
        const trimmedBaseUsername = baseUsername.slice(0, maxBaseLength);

        for (let i = 1; i <= 3; i++) {
          const newUsername = `${trimmedBaseUsername}${Math.floor(Math.random() * 1000)}`;
          const q = query(collection(this.db as Firestore, 'users'), where('userName', '==', newUsername));
          const querySnapshot = await getDocs(q);
    
          if (querySnapshot.empty) {
            suggestedUsernames.push(newUsername);
          }
    
          if (suggestedUsernames.length >= 3) {
            break;
          }
        }
        return suggestedUsernames;
      };

    isFriendRequestedUser= async(userId:string) => {
        try {
            this.checkUser(this.user)
        }catch(e){
            return
        }
        try{
            const q = query(collection(this.db, "/friendRequests"), or(and(where("receiverId", "==", userId), where("senderId", "==", this.user.uid)),and(where("senderId", "==", userId), where("receiverId", "==", this.user.uid))) );
            if ( process.env.NODE_ENV==="development" ) {
                console.log("q",q)
            }
            const snapshot = await getDocs(q);
            const result=[] as any
            snapshot.forEach((doc) => {
                // Récupérez les données du document
                result.push({...doc.data(), id:doc.id})
            
                // Utilisez les données comme vous le souhaitez
                if ( process.env.NODE_ENV==="development" ) {
                    console.log(result);
                }
            });
            return result && result.length
             
        }catch(e){
            if ( process.env.NODE_ENV==="development" ) {    
                console.log(e)
            }
            return false
        }
    }


    getUsersInfo = async(users:string[]) => {
        try {
            this.checkUser(this.user)
        }catch(e){
            return
        }
        try{
            const q = query(collection(this.db, "/users"), where("__name__", "in",users));
            if ( process.env.NODE_ENV==="development" ) {
                console.log("q",q, users)
            }
            const snapshot = await getDocs(q);
            if ( process.env.NODE_ENV==="development" ) {
                console.log("search email", snapshot)
            }
            const result=[] as any
            snapshot.forEach((doc) => {
                // Récupérez les données du document
                result.push({...doc.data(), id:doc.id})
            
                // Utilisez les données comme vous le souhaitez
                if ( process.env.NODE_ENV==="development" ) {
                    console.log(result);
                }
            });
            return result
             
        }catch(e){
            if ( process.env.NODE_ENV==="development" ) {
                console.log(e)
            }
            return false
        }
    }

    searchEmailInUsers = async(email:string) => {
        try {
            this.checkUser(this.user)
        }catch(e){
            return
        }
        try{
            const q = query(collection(this.db, "/users"), where("email", "==", email.toLowerCase()));
            if ( process.env.NODE_ENV==="development" ) {
                console.log("q",q)
            }
            const snapshot = await getDocs(q);
            if ( process.env.NODE_ENV==="development" ) {
                console.log("search email", snapshot)
            }
            const result=[] as any
            snapshot.forEach((doc) => {
                // Récupérez les données du document
                result.push({...doc.data(), id:doc.id})
            
                // Utilisez les données comme vous le souhaitez
                if ( process.env.NODE_ENV==="development" ) {
                    console.log(result);
                }
            });
            return result
             
        }catch(e){
            if ( process.env.NODE_ENV==="development" ) {
                console.log(e)
            }
            return false
        }
    }

    refuseTradeRequest = async (tradeRequestId:string) => {
        try {
            this.checkUser(this.user)
        }catch(e){
            return false
        }
        try{
            const tradeRequestRef = doc(this.db as Firestore, 'tradeRequests', tradeRequestId);
            await updateDoc(tradeRequestRef, { status: "rejected" });
        }catch(e){
            if ( process.env.NODE_ENV==="development" ) {
                console.log(e)
            }
            return false
        }
        return true
    }

    switchCreature = async (tradeRequest:TradeRequestType) => {
        try {
            this.checkUser(this.user)
        }catch(e){
            return false
        }
        try{
            
            const tradeCreatureRef = doc(this.db as Firestore, 'creatures', tradeRequest.senderOriginalCreatureId);
            const receiveCreatureRef = doc(this.db as Firestore, 'creatures', tradeRequest.receiverOriginalCreatureId);
            const tradeRequestRef = doc(this.db as Firestore, 'tradeRequests', tradeRequest.id);

            await runTransaction(this.db, async (transaction) => {
                // Récupérer les documents des deux créatures dans la transaction
                const tradeCreatureDoc = await transaction.get(tradeCreatureRef);
                const receiveCreatureDoc = await transaction.get(receiveCreatureRef);
                const tradeRequestDoc = await transaction.get(tradeRequestRef);
                
                if (!tradeCreatureDoc.exists() || !receiveCreatureDoc.exists() || !tradeRequestDoc.exists()) {  
                    throw new Error("One of the creatures does not exist!");
                }
                if (tradeRequestDoc.exists()) {
                    const trade = tradeRequestDoc.data();
                    const senderCreature = tradeCreatureDoc.data();
                    const receiverCreature = receiveCreatureDoc.data();

                    // Vérifier si les créatures sont toujours disponibles pour l'échange
                    if (senderCreature.ownerId !== trade.senderId || receiverCreature.ownerId !== trade.receiverId) {
                        transaction.update(tradeRequestRef, { status: "rejected" });
                        throw new Error("One of the creatures is no longer available for trade!");
                    }

                    if (trade.status !== "pending") {
                        throw new Error("Trade request is no longer pending!");
                    }
                } 
                
                // Effectuer le switch des ownerId
                transaction.update(tradeCreatureRef, { ownerId: tradeRequest.receiverId });
                transaction.update(receiveCreatureRef, { ownerId: tradeRequest.senderId });

                transaction.update(tradeRequestRef, { status: "accepted" });
            });

        }catch(e){
            if ( process.env.NODE_ENV==="development" ) {
                console.log(e)
            }
            return false
        }
        return true
    }

    getCreaturesInDoubleFromFriends = async(friendRequests:FriendRequestType[], myCreatures: MyCreaturesType[], creatureToTrade:string) => {
        try {
            this.checkUser(this.user)
        }catch(e){
            return
        }
        try{
           
            // Supposons que 'myCreatures' et 'friendRequests' soient déjà définis
            const myCreatureIds = myCreatures.map(creature => creature.creatureId); // Extraire les creatureId des créatures du joueur

            if ( process.env.NODE_ENV==="development" ) {
                console.log("myCreatureIds",myCreatureIds)
            }

            // Extraire les IDs des amis où le statut est accepté
            const friendIds = friendRequests
                .filter(request => request.status === 'approved')
                .map(request => request.senderId === this.user.uid ? request.receiverId : request.senderId);

            if ( process.env.NODE_ENV==="development" ) {
                console.log("friendIds",friendIds)  
            }
            // Créer la requête pour récupérer toutes les créatures des amis
            const q = query(
                collection(this.db as Firestore, 'creatures'),
                where('ownerId', 'in', friendIds)
            );

            // Exécuter la requête pour obtenir les créatures
            const querySnapshot = await getDocs(q);

            const friendCreatures = querySnapshot.docs.map(doc => ({
                id: doc.id,
                creatureId: doc.data().creatureId,
                ownerId: doc.data().ownerId,
                discovered: doc.data().discovered
            }));

            const r = query(
                collection(this.db as Firestore, 'tradeRequests'),
                and(or(where('senderId', 'in', friendIds),where('receiver', 'in', friendIds)),where('status', '==', 'pending'))
            );

            const querySnapshotTrade = await getDocs(r);
            const tradeRequests = querySnapshotTrade.docs.map(doc => ({
                id: doc.id,
                senderCreatureId: doc.data().senderCreatureId,
                receiverCreatureId: doc.data().receiverCreatureId,
                senderOriginalCreatureId: doc.data().senderOriginalCreatureId,
                receiverOriginalCreatureId: doc.data().receiverOriginalCreatureId
            }));

            // Filtrer les créatures en double que le joueur ne possède pas encore
            const doubleCreatures = friendCreatures
                .sort((a, b) => {
                    const nameComparison = (a.creatureId).localeCompare(b.creatureId);
                    if (nameComparison !== 0) {
                        return nameComparison;
                    } else {
                        return a.discovered - b.discovered; // Assuming discovered is a timestamp
                }})  // Trier par timestamp croissant
                .filter((creature, index, self) => {
                    // Vérifier si la créature est en double chez cet ami
                    //const isDouble = self.filter(c => c.creatureId === creature.creatureId && c.ownerId === creature.ownerId).length > 1;
                    /*const isDouble = self                        
                        .filter((c, index, array) => {
                        return (
                            c.creatureId === creature.creatureId &&
                            c.ownerId === creature.ownerId  &&
                            array.findIndex(item => item.creatureId === c.creatureId && item.ownerId === c.ownerId) !== index
                        );
                    }).length > 0;*/

                    const occurrences = self.filter(c => c.creatureId === creature.creatureId && c.ownerId === creature.ownerId);
                    const isDouble = occurrences.length > 1 && occurrences.indexOf(creature) !== 0;

                    // Vérifier si le joueur ne possède pas cette créature
                    const playerDoesNotOwn = !myCreatureIds.includes(creature.creatureId);
                    // Vérifier si l'ami ne possède pas déjà la créature que le joueur souhaite échanger
                    const friendDoesNotOwnTradeCreature = !self.find(c => c.ownerId === creature.ownerId && c.creatureId === creatureToTrade);
                    
                    if ( isDouble && playerDoesNotOwn && friendDoesNotOwnTradeCreature ){
                        if ( process.env.NODE_ENV==="development" ) {
                            console.log("player not own creature",myCreatureIds,creature.creatureId)
                        }
                    }
                    const isInTrade = tradeRequests && tradeRequests.length > 0 && tradeRequests.some(c => c.senderOriginalCreatureId === creature.id || c.receiverOriginalCreatureId === creature.id);
                    if ( process.env.NODE_ENV==="development" ) {
                        console.log("isInTrade",isDouble, playerDoesNotOwn, friendDoesNotOwnTradeCreature, isInTrade)
                    }
                    return isDouble && playerDoesNotOwn && friendDoesNotOwnTradeCreature && !isInTrade;
                })
                .reduce((result:any, creature:any) => {
                    if (!result.some((r:any) => r.creatureId === creature.creatureId)) {
                        result.push(creature);
                    }
                    return result;
                }, []);

            if ( process.env.NODE_ENV==="development" ) {
                console.log("doubleCreature",doubleCreatures);
            }
            return doubleCreatures;
             
        }catch(e){
            if ( process.env.NODE_ENV==="development" ) {
                console.log(e)
            }
            return false
        }
    }

    addFriendRequests= async(requestId:string, status:string ="pending") => {
        try {
            this.checkUser(this.user)
        }catch(e){
            return false
        }
        try {
            
            if ( requestId === this.user.uid ) return 
            const check = await this.isFriendRequestedUser(requestId)
            if ( !check ){
                const uuid = v4()
                const friendRequest = {
                    id : uuid,
                    receiverId: requestId,
                    senderId: this.user.uid,
                    status: status,
                    timestamp: new Date().getTime(),
                    rejectedBy: "",
                    isSynchronized: true
                } 
                if ( process.env.NODE_ENV==="development" ) {
                    console.log("addFriendRequests",friendRequest)
                }

                const docRef = doc(this.db as Firestore, `/friendRequests`, `${uuid}`)
                await setDoc(docRef, friendRequest );
                return true
            }
            return false
        }catch(e){
            if ( process.env.NODE_ENV==="development" ) {
                console.log(e)
            }
            return false
        }
    }

    updateFriendRequests= async(requestId:string,status:string) => {
        try {
            this.checkUser(this.user)
        }catch(e){
            return false
        }
        if ( process.env.NODE_ENV==="development" ) {
            console.log("WTFUPDATEFRIEND",!(["pending","approved","rejected"].indexOf(status) > -1), status)
        }
        if (!(["pending","approved","rejected"].indexOf(status) > -1)) {
            return false
        }
        if ( process.env.NODE_ENV==="development" ) {
            console.log("WTFUPDATEFRIEND2")
        }
        try {
            const friendRequestsRef = doc(this.db as Firestore, `/friendRequests`, `${requestId}`)
            if ( process.env.NODE_ENV==="development" ) {
                console.log("WTFCLOUDCONTACTV MAP contactToSync",friendRequestsRef)
            }
            const result = await updateDoc(friendRequestsRef, {
                status: status,
                rejectedBy: this.user.uid,
                isSynchronized: true
            } );
            if ( process.env.NODE_ENV==="development" ) {
                console.log("WTUPDATEFRIEND",result)
            }
            return true
        }catch(e){
            if ( process.env.NODE_ENV==="development" ) {
                console.log("WTUPDATEFRIEND",e)
            }
            return false
        }
    }
    
    subscribeToFriendRequests= (treatData:(friendRequests:FriendRequestType[], toRemove:FriendRequestType[])=>void,setIsLoading?: Dispatch<SetStateAction<boolean>>)=>{
        try {
            this.checkUser(this.user)
            if (!this.user.email || !this.user.email.length){
                return null
            }
            const q = query(collection(this.db, "/friendRequests"), or(where("receiverId", "==", this.user.uid), and(where("senderId", "==", this.user.uid), where("status", "==", "approved"))));
            return onSnapshot(q, (snapshot) => {
                if ( snapshot.metadata.fromCache ){ //|| snapshot.metadata.hasPendingWrites) {
                    setIsLoading && setIsLoading(false)
                    return
                }
                if ( process.env.NODE_ENV==="development" ) {
                    console.log("subscribeFriendRequests CHANGE")
                }
                const result = [] as any[]
                const resultToRemove = [] as any[]
                snapshot.docChanges().forEach((change) => {
                    const userInfo = change.doc.data() as FriendRequestType

                    if ( change.type === "removed" ){
                        resultToRemove.push(userInfo )
                    }else {
                        result.push(userInfo )  
                    }

                    if ( process.env.NODE_ENV==="development" ) {
                        console.log("subscribeFriendRequests",snapshot, change)
                    }
                });
                if ( result && result.length) {
                    treatData(this.fixAllToIsSynchronized(result) as FriendRequestType[], resultToRemove)
                }
                if ( !result || !result.length) {
                    setIsLoading && setIsLoading(false)
                }
            });     
        }catch(e){
            return null
        } 
    }


    setTradeRequestsToCloud= async(tradeRequests:TradeRequestType[])=> {
        try {
            this.checkUser(this.user)
        }catch(e){
            return false
        }
        try {
            if ( process.env.NODE_ENV==="development" ) {
                console.log("setTradeRequestsToCloud",tradeRequests)
            }
            const batch = writeBatch(this.db);
            tradeRequests.map(async (item, index) => {
                if ( process.env.NODE_ENV==="development" ) {
                    console.log("setTradeRequestsToCloud",item,index)
                }
                if (item) {        
                    if ( !item.isSynchronized ){                        
                        item.isSynchronized=true
                        const docRef = doc(this.db as Firestore, `tradeRequests`, `${item.id}`)
                        batch.set(docRef,{...item});
                        //await addDoc(collection(this.db as Firestore, `users/${this.user?.uid}/episodes/${epuuid}/contacts/${index}/conversation`), conversationToSync);
                        if ( process.env.NODE_ENV==="development" ) {
                            console.log("setTradeRequestsToCloud",item)
                        }
                    }
                }
            })
            await batch.commit();
            //await Promise.all(contacts.map((data) => collectionRef.doc(data.id.toString()).set(data)));
        } catch (error) {
            console.error('Erreur lors de la mise à jour de la collection :', error);
            return false
        }
        return true
    }


    subscribeToTradeRequests= (treatData:(tradeRequests:TradeRequestType[])=>void,setIsLoading?: Dispatch<SetStateAction<boolean>>)=>{
        try {
            this.checkUser(this.user)
            if (!this.user.email || !this.user.email.length){
                return null
            }
            const q = query(collection(this.db, "/tradeRequests"), or(where("receiverId", "==", this.user.uid), where("senderId", "==", this.user.uid)));
            return onSnapshot(q, (snapshot) => {
                if ( snapshot.metadata.fromCache ){ //|| snapshot.metadata.hasPendingWrites) {
                    setIsLoading && setIsLoading(false)
                    return
                }
                if ( process.env.NODE_ENV==="development" ) {
                    console.log("subscribeToTradeRequests CHANGE")
                }
                const result = [] as any[]
                snapshot.docChanges().forEach((change) => {
                    const trade = change.doc.data() as TradeRequestType

                    //if ( userInfo && userInfo.status != "rejected"){
                    if ( trade) {    
                        result.push({...trade, isSynchronized:true})
                    }
                    if ( process.env.NODE_ENV==="development" ) {
                        console.log("subscribeToTradeRequests",snapshot, change)
                    }
                });
                if ( result && result.length) {
                    treatData(this.fixAllToIsSynchronized(result) as TradeRequestType[])
                }
                if ( !result || !result.length) {
                    setIsLoading && setIsLoading(false)
                }
            });     
        }catch(e){
            return null
        } 
    }

    getConfigFromCloud= async(): Promise<ConfigType|null> =>{
        try {
            this.checkUser(this.user)
        }catch(e){
            return null
        }
        
        const data = await getDoc(doc(this.db as Firestore, `/users/${this.user?.uid}/global`, `config`))
        if ( process.env.NODE_ENV==="development" ) {
            console.log("CloudStorageFirestoreHelper getConfigFromCloud", data)
        }
        if (data.exists()) {
            // Récupérer l'objet final
            return data.data() as ConfigType;
        } 
        return {
            allowFart: true,
            allowAutoVocal: true,
            allowNotifications: true,
            allowBeep: true
        } as ConfigType;
        
    }

    setConfigToCloud= async(config:ConfigType)=> {
        try {
            this.checkUser(this.user)
        }catch(e){
            return
        }
        
        try {
            if ( process.env.NODE_ENV==="development" ) {
                console.log("CloudStorageFirestoreHelper setConfigToCloud",config)
            }
            const docRef = doc(this.db as Firestore, `/users/${this.user?.uid}/global`, `config`)
            await setDoc(docRef, config);
            //await Promise.all(contacts.map((data) => collectionRef.doc(data.id.toString()).set(data)));
        } catch (error) {
            if ( process.env.NODE_ENV==="development" ) {
                console.error('Erreur lors de la mise à jour de la collection :', error);
            }
        }
        return
        
    }

    getAnaDiscussionFromCloud= async(): Promise<AnaDiscussionStatusType|null> =>{
        try {
            this.checkUser(this.user)
        }catch(e){
            return null
        }
        
        const data = await getDoc(doc(this.db as Firestore, `/users/${this.user?.uid}/global`, `ana`))
        if ( process.env.NODE_ENV==="development" ) {
            console.log("CloudStorageFirestoreHelper getConfigFromCloud", data)
        }
        if (data.exists()) {
            // Récupérer l'objet final
            return data.data() as AnaDiscussionStatusType;
        } 
        return {} as AnaDiscussionStatusType;
        
    }

    setAnaDiscussionToCloud= async(ana:AnaDiscussionStatusType)=> {
        try {
            this.checkUser(this.user)
        }catch(e){
            return
        }
        
        try {
            if ( process.env.NODE_ENV==="development" ) {
                console.log("CloudStorageFirestoreHelper setConfigToCloud",ana)
            }
            const docRef = doc(this.db as Firestore, `/users/${this.user?.uid}/global`, `ana`)
            await setDoc(docRef, {...ana, isSynchronized: true});
            //await Promise.all(contacts.map((data) => collectionRef.doc(data.id.toString()).set(data)));
        } catch (error) {
            if ( process.env.NODE_ENV==="development" ) {
                console.error('Erreur lors de la mise à jour de la collection :', error);
            }
        }
        return
        
    }

    subscribeToAnaDiscussionStatus=(treatData:(ana:AnaDiscussionStatusType)=>void, setIsLoading?: Dispatch<SetStateAction<boolean>>)=>{
        try {
            this.checkUser(this.user)
        }catch(e){
            return null
        }
        
        const ref = doc(this.db as Firestore, `/users/${this.user?.uid}/global`, `ana`)
        return onSnapshot(ref, (querySnapshot) => {
            if ( querySnapshot.metadata.fromCache ){ //|| snapshot.metadata.hasPendingWrites) {
                setIsLoading && setIsLoading(false)
                return
            }
            if ( process.env.NODE_ENV==="development" ) {
                console.log("subscribeToAna",querySnapshot.data(), querySnapshot, querySnapshot.metadata)
            }             
            if ( querySnapshot.data() ) {
                const data = querySnapshot.data() as AnaDiscussionStatusType
                treatData({...data, isSynchronized:true})
            }else{
                treatData({} as AnaDiscussionStatusType)
                setIsLoading && setIsLoading(false)
            }
        })        
    }

    getScoreFromCloud= async(): Promise<ScoreType|null> =>{
        try {
            this.checkUser(this.user)
        }catch(e){
            return null
        }
        
        const data = await getDoc(doc(this.db as Firestore, `/users/${this.user?.uid}/global`, `score`))
        if ( process.env.NODE_ENV==="development" ) {
            console.log("CloudStorageFirestoreHelper getScoreFromCloud", data)
        }
        if (data.exists()) {
            // Récupérer l'objet final
            return data.data() as ScoreType;
        } 
        return {
            points: 0,
            medalsA: 0,
            medalsB: 0,
            medalsC: 0
        } as ScoreType;
        
    }

    setScoreToCloud= async(score:ScoreType)=> {
        try {
            this.checkUser(this.user)
        }catch(e){
            return null
        }
        
        try {
            if ( process.env.NODE_ENV==="development" ) {
                console.log("CloudStorageFirestoreHelper setScoreToCloud",score)
            }
            const docRef = doc(this.db as Firestore, `/users/${this.user?.uid}/global`, `score`)
            await setDoc(docRef, score);
            //await Promise.all(contacts.map((data) => collectionRef.doc(data.id.toString()).set(data)));
        } catch (error) {
            if ( process.env.NODE_ENV==="development" ) {console.error('Erreur lors de la mise à jour de la collection :', error);}
        }
        return
        
    }

    subscribeToConfig=(treatData:(config:ConfigType)=>void)=>{
        try {
            this.checkUser(this.user)
        }catch(e){
            return null
        }
        
        const ref = doc(this.db as Firestore, `/users/${this.user?.uid}/global`, `config`)
        return onSnapshot(ref, (querySnapshot) => {
            if ( process.env.NODE_ENV==="development" ) {
                console.log("subscribeToConfig",querySnapshot.data(), querySnapshot, querySnapshot.metadata)
            }
            if ( querySnapshot.data() ) {
                treatData(querySnapshot.data() as ConfigType)
            }else{
                treatData({} as ConfigType)
            }
        })
        
    }

    subscribeToScore=(treatData:(score:ScoreType)=>void)=>{
        try {
            this.checkUser(this.user)
        }catch(e){
            return null
        }
        
        const ref = doc(this.db as Firestore, `/users/${this.user?.uid}/global`, `score`)
        return onSnapshot(ref, (querySnapshot) => {
            if ( process.env.NODE_ENV==="development" ) {
                console.log("subscribeToScore",querySnapshot.data(), querySnapshot, querySnapshot.metadata)
            }
            if ( querySnapshot.data() ) {
                treatData(querySnapshot.data() as ScoreType)
            }else{
                treatData({} as ScoreType)
            }
        })        
    }

    updateLeaderboard= async(zoneId: string, user: { userId: string, userPhotoUrl: string, userName: string }, timestamp: number) => {
        try {
            this.checkUser(this.user)
        }catch(e){
            return null
        }
        const zoneRef = doc(this.db as Firestore, 'zones', zoneId);
        const zoneSnapshot = await getDoc(zoneRef);
      
        if (!zoneSnapshot.exists()) {
          console.error('Zone not found');
          return;
        }
      
        const zoneData = zoneSnapshot.data() as ZoneType;
        const leaderboard = zoneData.leaderboard || []; // Récupérer le leaderboard existant ou initialiser un tableau vide
      
        // Vérifier si l'utilisateur existe déjà dans le leaderboard
        const existingEntryIndex = leaderboard.findIndex(entry => entry.userId === user.userId);
      
        if (existingEntryIndex !== -1) {
          // Si l'utilisateur est déjà dans le leaderboard, mettre à jour ses points et son timestamp
          leaderboard[existingEntryIndex] = {
            ...leaderboard[existingEntryIndex],
            timestamp: timestamp
          };
        } else {
          // Sinon, ajouter un nouvel utilisateur dans le leaderboard
          leaderboard.push({
            userId: user.userId,
            userPhotoUrl: user.userPhotoUrl,
            userName: user.userName,
            timestamp: timestamp
          });
        }
      
        leaderboard.sort((a, b) => a.timestamp - b.timestamp);
      
        // Garder uniquement les 10 meilleurs joueurs
        const updatedLeaderboard = leaderboard.slice(0, 10);
      
        // Mettre à jour le document de la zone dans Firestore avec le nouveau leaderboard
        await updateDoc(zoneRef, { leaderboard: updatedLeaderboard });
    }

    setUserStateToCloud= async(state:UserStateType)=> {
        try {
            this.checkUser(this.user)
        }catch(e){
            return null
        }
        
        try {
            if ( process.env.NODE_ENV==="development" ) {
                console.log("CloudStorageFirestoreHelper setScoreToCloud",state)
            }
            //const docRef = doc(this.db as Firestore, `/users/${this.user?.uid}/global`, `state`)
            const docRef = doc(this.db as Firestore, `/userStates`, `${this.user?.uid}`)
            await setDoc(docRef, {...state, 
                userId: this.user?.uid,
                isSynchronized: true    ,
                lastActivity: new Date().getTime()            
            },{ merge: true });
            //await Promise.all(contacts.map((data) => collectionRef.doc(data.id.toString()).set(data)));
        } catch (error) {
            if ( process.env.NODE_ENV==="development" ) {console.error('Erreur lors de la mise à jour de la collection :', error);}
        }
        return
        
    }

    subscribeToUserState=(treatData:(state:UserStateType)=>void, setIsLoading?: Dispatch<SetStateAction<boolean>>)=>{
        try {
            this.checkUser(this.user)

            // const q = query(collection(this.db, "/userStates"), where("__name__", "in",userIds));
            const q = doc(this.db as Firestore, `/userStates/`, `${this.user?.uid}`)
            return onSnapshot(q, (snapshot) => {
                if ( snapshot.metadata.fromCache ){ //|| snapshot.metadata.hasPendingWrites) {
                    setIsLoading && setIsLoading(false)
                    return
                }
        
        
            if ( process.env.NODE_ENV==="development" ) {
                console.log("subscribeToUserState",snapshot.data(), snapshot, snapshot.metadata)
            }
            if ( snapshot.data() ) {
                const data = snapshot.data() as UserStateType
                treatData({...data, isSynchronized:true})
            }else{
                treatData({
                    userId: this.user?.uid,
                    points: 0,
                    gooblies: 0,
                    xp: 0,
                    badges: [],
                    kilometers: 0,
                    food: 0,
                    lastActivity: 0,
                    isSynchronized: true
                }as UserStateType)
                //setIsLoading && setIsLoading(false)
            }})

        }catch(e){
            return null
        }          
    }
    
    subscribeToAllUserState=(userIds:string[],treatData:(state:UserStateType[])=>void)=>{
       
        try {
            this.checkUser(this.user)

            if (!userIds || !userIds.length){
                return null
            }
            const q = query(collection(this.db, "/userStates"), where("__name__", "in",userIds));
            if ( process.env.NODE_ENV==="development" ) {
                console.log("subscribeToAllUserState",q)
            }
            return onSnapshot(q, (snapshot) => {
                if ( process.env.NODE_ENV==="development" ) {
                    console.log("subscribeToAllUserState",snapshot)
                }
                /*if (  snapshot.metadata.hasPendingWrites) {                    
                    return
                }*/
                if ( process.env.NODE_ENV==="development" ) {
                    console.log("subscribeToAllUserState CHANGE")
                }
                const result = [] as UserStateType[]
                snapshot.docChanges().forEach((change) => {
                    const userInfo = change.doc.data() as UserStateType

                    //if ( userInfo && userInfo.status != "rejected"){
                    if ( userInfo) {    
                        result.push(userInfo)
                    }
                    if ( process.env.NODE_ENV==="development" ) {
                        console.log("subscribeToAllUserState",snapshot, change)
                    }
                });
                if ( result && result.length) {
                    treatData(result)
                }
            });  
        }catch(e){
            if ( process.env.NODE_ENV==="development" ) {
                console.log("subscribeToAllUserState",e)
            }
            return null
        }          
    }

    getAllUserState=async(userIds:string[])=>{
       
        try {
            this.checkUser(this.user)

            if (!userIds || !userIds.length){
                return null
            }
            const q = query(collection(this.db, "/userStates"), where("__name__", "in",userIds));
            if ( process.env.NODE_ENV==="development" ) {
                console.log("subscribeToAllUserState",q)
            }

            const result = [] as UserStateType[]
            const snapshot = await getDocs(q);
            snapshot.forEach((doc:any) => {
                const action = doc.data() 
                result.push(action)  
            });

            return result
        }catch(e){
            if ( process.env.NODE_ENV==="development" ) {
                console.log("subscribeToAllUserState",e)
            }
            return null
        }          
    }

    subscribeToZone= (location:any, treatData:(data:ZoneType[])=>void, setIsLoading?: Dispatch<SetStateAction<boolean>>)=>{
        try {
            this.checkUser(this.user)
        }catch(e){
            return null
        }
        if ( !location || !location.lat || !location.lng ) 
            return []
        const ref = collection(this.db as Firestore, `/zones`)

        // Calcul de la bounding box pour un rayon de 1 km autour de l'utilisateur
        const distance = 1000; // Distance en km
        const { minLat, maxLat, minLng, maxLng } = calculateBoundingBox(location.lat, location.lng, distance);

        // Query pour les zones publiques dans la bounding box
        const publicZonesQuery = query(ref,
            where("shared", "==", "public"),
            where("startLat", ">=", minLat),
            where("startLat", "<=", maxLat),
            where("startLng", ">=", minLng),
            where("startLng", "<=", maxLng)
        );

        const privateZonesQuery = query(ref,
            where("ownerId", "==", this.user.uid),
        );

        return [onSnapshot(publicZonesQuery, (snapshot) => {
            if ( process.env.NODE_ENV==="development" ) {
                console.log("WTFSNAPCHOT ZONE FROM CACHE")
            }
            if ( snapshot.metadata.fromCache) {
                setIsLoading && setIsLoading(false)
                return
            }
            const zones:any[]=[]
            snapshot.forEach(doc => {
                const zone = doc.data();                
                zones.push(zone)  
            });
            if ( zones && zones.length) {
                treatData(zones)
            }else{
                setIsLoading && setIsLoading(false)
            }
        }),onSnapshot(privateZonesQuery, (snapshot) => {
            if ( process.env.NODE_ENV==="development" ) {
                console.log("WTFSNAPCHOT ZONE FROM CACHE")
            }
            if ( snapshot.metadata.fromCache) {
                setIsLoading && setIsLoading(false)
                return
            }
            const zones:any[]=[]
            snapshot.forEach(doc => {
                const zone = doc.data();                
                zones.push(zone)  
            });
            if ( zones && zones.length) {
                treatData(zones)
            }else{
                setIsLoading && setIsLoading(false)
            }
        })];

        /*
        return onSnapshot(query(ref, where('ownerId', '==', this.user.uid)), (querySnapshot) => {
            
            if (querySnapshot.metadata.fromCache ){//|| querySnapshot.metadata.hasPendingWrites) {
                
                //return
            }
            
            const docs = querySnapshot.docChanges()
            const zones:any[]=[]
            docs.map( (doc:any)=>{
                const zone = doc.doc.data()
                zones.push(zone)      
                if ( zones && zones.length) {
                    treatData(zones)
                }
            })
        });*/
    }

    addZoneToCloud= async(zone:ZoneType)=> {
        try {
            this.checkUser(this.user)
        }catch(e){
            return
        }
        try {
            if ( process.env.NODE_ENV==="development" ) {
                console.log("WTFCLOUDCONTACTV",zone)
            }
            if (zone) {
                const uuid=v4()
                const docRef = doc(this.db as Firestore, `/zones`, zone.id!=="0"?zone.id:uuid);
                const { coords, ...zoneWithoutCoords } = zone;

                // Synchroniser le tableau coords??uuid
                const batch = writeBatch(this.db);
                
                batch.set(docRef,{...zone,
                    id:zone.id!=="0"?zone.id:uuid, 
                    ownerId:this.user.uid,
                    startLat: coords[0]?.lat ?? 0,
                    startLng: coords[0]?.lng ?? 0,
                });

                // Appliquer le batch
                await batch.commit();
                return {...zone,id:zone.id!=="0"?zone.id:uuid}
            }
        } catch (error) {
            if ( process.env.NODE_ENV==="development" ) {
                console.error('Erreur lors de la mise à jour de la collection :', error);
            }
        }
        return
    }

    subscribeToMyItems= (treatData:(data:MyItemsType[], dataToRmove:MyItemsType[])=>void, setIsLoading?: Dispatch<SetStateAction<boolean>>)=>{
        try {
            this.checkUser(this.user)
        }catch(e){
            return null
        }
        const ref = collection(this.db as Firestore, `/users/${this.user?.uid}/items`)

        return onSnapshot(query(ref), (querySnapshot) => {
            
            if ( process.env.NODE_ENV==="development" ) {
                console.log("WTFSNAPCHOT ZONE DOCHANGE",querySnapshot.docChanges(), querySnapshot, querySnapshot.metadata)
            }
            if (querySnapshot.metadata.fromCache ){//|| querySnapshot.metadata.hasPendingWrites) {
                if ( process.env.NODE_ENV==="development" ) {
                    console.log("WTFSNAPCHOT ZONE FROM CACHE")
                }
                return
            }
            
            const docs = querySnapshot.docChanges()
            const eggs:any[]=[]
            const itemsToRemove:any[]=[]
            docs.map( (doc:any)=>{
                if ( process.env.NODE_ENV==="development" ) {
                    console.log("WTF changes ZONE",doc.doc.data())
                }  
                const egg = doc.doc.data()
                if ( process.env.NODE_ENV==="development" ) {
                    console.log("wtf COORDS LAST GOOD INFO + TYPE", {...egg}, doc.type)
                }
                if ( doc.type === "removed" ){
                    itemsToRemove.push(egg)
                }else {
                    eggs.push(egg)  
                }
                              
            
                if ( process.env.NODE_ENV==="development" ) {
                    console.log("WTF changes zones",eggs)
                }                
            })
            if ( eggs && eggs.length || itemsToRemove && itemsToRemove.length) {
                treatData(this.fixAllToIsSynchronized(eggs as MyCreaturesType[]), itemsToRemove)
            }
            if ( !eggs || !eggs.length) {
                setIsLoading && setIsLoading(false)
            }
        });
    }

    subscribeToMyCreatures= (treatData:(data:MyCreaturesType[], dataToRmove:MyCreaturesType[])=>void, setIsLoading?: Dispatch<SetStateAction<boolean>>)=>{
        try {
            this.checkUser(this.user)
        }catch(e){
            return null
        }
        const ref = collection(this.db as Firestore, `/creatures`)

        return onSnapshot(query(ref, where('ownerId', '==', this.user.uid)), (querySnapshot) => {
            
            if ( process.env.NODE_ENV==="development" ) {
                console.log("WTFSNAPCHOT ZONE DOCHANGE",querySnapshot.docChanges(), querySnapshot, querySnapshot.metadata)
            }
            if (querySnapshot.metadata.fromCache ){//|| querySnapshot.metadata.hasPendingWrites) {
                if ( process.env.NODE_ENV==="development" ) {
                    console.log("WTFSNAPCHOT ZONE FROM CACHE")
                }
                return
            }
            
            const docs = querySnapshot.docChanges()
            const creatures:any[]=[]
            const creaturesToRemove:any[]=[]
            docs.map( (doc:any)=>{
                if ( process.env.NODE_ENV==="development" ) {
                    console.log("WTF changes ZONE",doc.doc.data())
                }  
                const zone = doc.doc.data()
                if ( process.env.NODE_ENV==="development" ) {
                    console.log("wtf COORDS LAST GOOD INFO + TYPE", {...zone}, doc.type)
                }
                if ( doc.type === "removed" ){
                    creaturesToRemove.push(zone)
                }else {
                    creatures.push(zone)  
                }
                              
            
                if ( process.env.NODE_ENV==="development" ) {
                    console.log("WTF changes zones",creatures)
                }                
            })
            if ( creatures && creatures.length || creaturesToRemove && creaturesToRemove.length) {
                treatData(this.fixAllToIsSynchronized(creatures as MyCreaturesType[]), creaturesToRemove)
            }
            if ( !creatures || !creatures.length) {
                setIsLoading && setIsLoading(false)
            }
        });
    }

    setItemsToCloud= async(data:MyItemsType[])=> {
        try {
            this.checkUser(this.user)
        }catch(e){
            return false
        }
        try {
            if ( process.env.NODE_ENV==="development" ) {
                console.log("setItemsToCloud",data)
            }
            const batch = writeBatch(this.db);
            data.map(async (item, index) => {
                if ( process.env.NODE_ENV==="development" ) {
                    console.log("setItemsToCloud",item,index)
                }
                if (item) {        
                    if ( !item.isSynchronized ){                        
                        item.isSynchronized=true
                        const docRef = doc(this.db as Firestore, `/users/${this.user?.uid}/items`, `${item.id}`)
                        batch.set(docRef,{...item, isSynchronized:true});
                        //await addDoc(collection(this.db as Firestore, `users/${this.user?.uid}/episodes/${epuuid}/contacts/${index}/conversation`), conversationToSync);
                        if ( process.env.NODE_ENV==="development" ) {
                            console.log("setItemsToCloud",item)
                        }
                    }
                }
            })
            await batch.commit();
            //await Promise.all(contacts.map((data) => collectionRef.doc(data.id.toString()).set(data)));
        } catch (error) {
            console.error('Erreur lors de la mise à jour de la collection :', error);
            return false
        }
        return true
    }

    setCreaturesToCloud= async(creatures:MyCreaturesType[])=> {
        try {
            this.checkUser(this.user)
        }catch(e){
            return false
        }
        try {
            if ( process.env.NODE_ENV==="development" ) {
                console.log("setCreaturesToCloud",creatures)
            }
            const batch = writeBatch(this.db);
            creatures.map(async (item, index) => {
                if ( process.env.NODE_ENV==="development" ) {
                    console.log("setCreaturesToCloud",item,index)
                }
                if (item) {        
                    if ( !item.isSynchronized ){                        
                        item.isSynchronized=true
                        const docRef = doc(this.db as Firestore, `/creatures`, `${item.id}`)
                        batch.set(docRef,{...item, ownerId:this.user.uid, isSynchronized:true});
                        //await addDoc(collection(this.db as Firestore, `users/${this.user?.uid}/episodes/${epuuid}/contacts/${index}/conversation`), conversationToSync);
                        if ( process.env.NODE_ENV==="development" ) {
                            console.log("setCreaturesToCloud",item)
                        }
                    }
                }
            })
            await batch.commit();
            //await Promise.all(contacts.map((data) => collectionRef.doc(data.id.toString()).set(data)));
        } catch (error) {
            console.error('Erreur lors de la mise à jour de la collection :', error);
            return false
        }
        return true
    }

    deleteItemsFromCloud= async(id:string)=> {
        try {
            this.checkUser(this.user)
        }catch(e){
            return 
        }
        try {
            
            const docRef = doc(this.db as Firestore, `/users/${this.user?.uid}/items`, `${id}`)
            await deleteDoc(docRef);
            
            //await Promise.all(contacts.map((data) => collectionRef.doc(data.id.toString()).set(data)));
        } catch (error) {
            console.error('Erreur lors de la mise à jour de la collection :', error);
        }
        return 
    }

    deleteCreaturesFromCloud= async(id:string)=> {
        try {
            this.checkUser(this.user)
        }catch(e){
            return 
        }
        try {
            
            const docRef = doc(this.db as Firestore, `/creatures`, `${id}`)
            await deleteDoc(docRef);
            
            //await Promise.all(contacts.map((data) => collectionRef.doc(data.id.toString()).set(data)));
        } catch (error) {
            console.error('Erreur lors de la mise à jour de la collection :', error);
        }
        return 
    }

    setActionHistoryToCloud= async(actionHistory:ActionHistoryType[])=> {
        try {
            this.checkUser(this.user)
        }catch(e){
            return false
        }
        try {
            if ( process.env.NODE_ENV==="development" ) {
                console.log("setActionHistoryToCloud",actionHistory)
            }
            const batch = writeBatch(this.db);
            actionHistory.map(async (item, index) => {
                if ( process.env.NODE_ENV==="development" ) {
                    console.log("setActionHistoryToCloud",item,index)
                }
                if (item) {        
                    if ( !item.isSynchronized ){                        
                        item.isSynchronized=true
                        const docRef = doc(this.db as Firestore, `/users/${this.user?.uid}/actionHistory`, `${item.id}`)
                        batch.set(docRef,{...item, isSynchronized:true}, { merge: true });
                        //await addDoc(collection(this.db as Firestore, `users/${this.user?.uid}/episodes/${epuuid}/contacts/${index}/conversation`), conversationToSync);
                        if ( process.env.NODE_ENV==="development" ) {
                            console.log("setActionHistoryToCloud",item)
                        }
                    }
                }
            })
            await batch.commit();
            //await Promise.all(contacts.map((data) => collectionRef.doc(data.id.toString()).set(data)));
        } catch (error) {
            console.error('Erreur lors de la mise à jour de la collection :', error);
            return false
        }
        return true
    }

    getActionHistoryFromCloud= async(): Promise<ActionHistoryType[]|null> =>{
        try {
            this.checkUser(this.user)
        }catch(e){
            return null
        }
        const result = [] as ActionHistoryType[]
        const ref = collection(this.db as Firestore, `/users/${this.user?.uid}/actionHistory`)
        const q = query(ref, orderBy('__name__', 'desc'), limit(100));

        const snapshot = await getDocs(q);
        snapshot.forEach((doc:any) => {
            const action = doc.data() 
            result.push({...action,date:action.date.toDate()})  
        });

        if ( process.env.NODE_ENV==="development" ) {
            console.log("getActionHistoryFromCloud ", result)
        }
        return result
        
    }

    setMyArtifactsToCloud= async(myArtifacts:MyArtifactType[])=> {
        try {
            this.checkUser(this.user)
        }catch(e){
            return false
        }
        try {
            if ( process.env.NODE_ENV==="development" ) {
                console.log("setMyArtifactsToCloud",myArtifacts)
            }
            const batch = writeBatch(this.db);
            myArtifacts.map(async (item, index) => {
                if ( process.env.NODE_ENV==="development" ) {
                    console.log("setMyArtifactsToCloud",item,index)
                }
                if (item) {        
                    const docRef = doc(this.db as Firestore, `/users/${this.user?.uid}/artifacts`, `${item.id}`)
                    batch.set(docRef,{...item}, { merge: true });
                    //await addDoc(collection(this.db as Firestore, `users/${this.user?.uid}/episodes/${epuuid}/contacts/${index}/conversation`), conversationToSync);
                    if ( process.env.NODE_ENV==="development" ) {
                        console.log("setMyArtifactsToCloud",item)
                    }
                }
            })
            await batch.commit();
            //await Promise.all(contacts.map((data) => collectionRef.doc(data.id.toString()).set(data)));
        } catch (error) {
            console.error('Erreur lors de la mise à jour de la collection :', error);
            return false
        }
        return true
    }

    getMyArtifactsFromCloud= async(): Promise<MyArtifactType[]|null> =>{
        try {
            this.checkUser(this.user)
        }catch(e){
            return null
        }
        const result = [] as MyArtifactType[]
        const ref = collection(this.db as Firestore, `/users/${this.user?.uid}/artifacts`)
        const q = query(ref, limit(100));

        const snapshot = await getDocs(q);
        snapshot.forEach((doc:any) => {
            result.push(doc.data())  
        });

        if ( process.env.NODE_ENV==="development" ) {
            console.log("getMyArtifactsFromCloud ", result)
        }
        return result
        
    }

    subscribeToActionHistory= (treatData:(data:ActionHistoryType[])=>void, setIsLoading?: Dispatch<SetStateAction<boolean>>)=>{
        try {
            this.checkUser(this.user)
        }catch(e){
            return null
        }
        const ref = collection(this.db as Firestore, `/users/${this.user?.uid}/actionHistory`)

        return onSnapshot(query(ref), (querySnapshot) => {
            
            if ( process.env.NODE_ENV==="development" ) {
                console.log("WTFSNAPCHOT subscribeToActionHistory",querySnapshot.docChanges(), querySnapshot, querySnapshot.metadata)
            }
            if (querySnapshot.metadata.fromCache ){//|| querySnapshot.metadata.hasPendingWrites) {
                setIsLoading && setIsLoading(false)
                if ( process.env.NODE_ENV==="development" ) {
                    console.log("WTFSNAPCHOT subscribeToActionHistory CACHE")
                }
                return
            }
            
            const docs = querySnapshot.docChanges()
            const actionHistory:any[]=[]
            docs.map( (doc:any)=>{
                if ( process.env.NODE_ENV==="development" ) {
                    console.log("WTF subscribeToActionHistory",doc.doc.data())
                }  
                const action = doc.doc.data()
                actionHistory.push({...action,date:action.date.toDate()})                 
            })
            if ( actionHistory && actionHistory.length) {
                treatData(this.fixAllToIsSynchronized(actionHistory as ActionHistoryType[]))
            }else{
                setIsLoading && setIsLoading(false)
            }
        });
    }

    addPaymentToCloud = async(purchase:PaymentType) => {
        try {
            this.checkUser(this.user)
        }catch(e){
            return null
        }
        if ( process.env.NODE_ENV==="development" ) {
            console.log("addpayment to cloud")
        }
        const docRef = doc(this.db as Firestore, `/payments`, `${purchase.id}`)
        await setDoc(docRef, {...purchase, userId:this.user.uid} );
    }

    subscribeToPayment= ( treatData:(paidcontent:PaymentType[])=>void, setIsLoading:Dispatch<SetStateAction<boolean>>)=>{
        try {
            this.checkUser(this.user)
        }catch(e){
            return null
        }
        try {
            const ref = collection(this.db as Firestore, `/payments`)
            if ( process.env.NODE_ENV==="development" ) {
                console.log("subscribeToPaidContent onSnapshot")
            }

            return onSnapshot(query(ref, where("userId", "==", this.user.uid)), (querySnapshot) => {
                setIsLoading(false)
                if ( process.env.NODE_ENV==="development" ) {
                    console.log("WTFSNAPCHOT PAIDCONTENT DOCHANGE",querySnapshot.docChanges(), querySnapshot, querySnapshot.metadata)
                }
                if (querySnapshot.metadata.fromCache ) {
                    if ( process.env.NODE_ENV==="development" ) {
                        console.log("WTFSNAPCHOT PAIDCONTENT FROM CACHE")
                    }
                    return
                }
                
                const docs = querySnapshot.docChanges()

                const paidContent:PaymentType[]=[]

                docs.map((doc)=>{
                    if ( process.env.NODE_ENV==="development" ) {
                        console.log("WTF changes PAIDCONTENT",doc.doc.data())
                    }
                    if ( doc.type == "added"){
                        if ( process.env.NODE_ENV==="development" ) {
                            console.log("WTF changes PAIDCONTENT",doc.doc.data())
                        }
                        paidContent.push(doc.doc.data() as PaymentType)
                    }
                })
                if ( process.env.NODE_ENV==="development" ) {
                    console.log("WTF changes PAIDCONTENT",paidContent)
                }
                if ( paidContent && paidContent.length) {
                    treatData(stripeToPaidHelper(paidContent))
                }else{
                    setIsLoading(false)
                }
            });
        }catch($e){
            console.error("subscribeToPaidContent", $e)
            return null
        }
    }

    setCoordinatesToCloud= async(lat:number,long:number)=> {
        try {
            this.checkUser(this.user)
        }catch(e){
            return null
        }
        const geohash = geohashForLocation([lat, long]);
        const db = getDatabase();
        set(ref(db, 'users/' + this.user.uid), {
            geohash: geohash,
            lat: lat,
            lng: long,
            userId: this.user.uid,
            photoUrl: this.user.photoURL,
            name: this.user.displayName,
            expiresAt: Date.now() + 1000 * 60 * 30
        });
    }

    listenToPlayersPositions = (handle:(data:PlayersType[])=>void) => {
        try {
            this.checkUser(this.user)
        }catch(e){
            return ()=>{}
        }
        const db = getDatabase(); // Initialise la base de données
        const usersRef = ref(db, '/users'); // Référence au nœud /users

         // Obtenir le temps actuel en millisecondes
        const currentTime = Date.now();

        // Créer une requête qui filtre les utilisateurs avec `expiresAt` supérieur au temps actuel
        const filteredUsersQuery = queryDB(usersRef, orderByChild('expiresAt'), startAt(currentTime), limitToFirst(1000));

        //const limitedUsersQuery = queryDB(usersRef, limitToFirst(1000));
        // Écoute les changements en temps réel sur /users
        const unsubscribe = onValue(filteredUsersQuery, (snapshot) => {
          const usersData = snapshot.val();
          const usersList:PlayersType[] = [];
      
          // Boucle sur les utilisateurs pour extraire leurs lat/lng
          for (let userId in usersData) {
            const user = usersData[userId];
            if ( userId !== this.user.uid){
                usersList.push({
                    geohash: user.geohash,
                    userId: userId,
                    lat: user.lat,
                    lng: user.lng,
                    name: user.name,
                    photoUrl: user.photoUrl,
                    expiresAt: user.expiresAt
                });
            }
          }
      
          // Utilise la fonction handle pour gérer les mises à jour de données
          handle(usersList);
        });
      
        // Retourne une fonction de nettoyage qui arrête l'écoute
        return () => {
            unsubscribe && unsubscribe();
        };
      };

    getNearbyUsers=async (playerLocation:any, radiusInKm:number=5) =>{
        const db = getDatabase();
        const usersRef = ref(db, 'users');

        // Calculer les boîtes geohash pour couvrir le rayon spécifié
        const bounds = geohashQueryBounds([playerLocation.lat, playerLocation.lng], radiusInKm * 1000); // Convertir km en mètres
        const matchingUsers:any = [];
        // Effectuer une requête pour chaque boîte geohash
        for (const b of bounds) {
            const q = queryDB(usersRef, orderByChild('geohash'), startAt(b[0]), endAt(b[1]), limitToFirst(5));
            const snapshot = await get(q);

            snapshot.forEach((userSnapshot:any) => {
                const user = userSnapshot.val();
                const distance = distanceBetween([user.lat, user.lng], [playerLocation.lat, playerLocation.lng]) * 1000; // Distance en mètres

                if (distance <= radiusInKm * 1000) {
                    matchingUsers.push({
                        ...user,
                        distance: distance
                    });
                }
            });
        }
        return matchingUsers;
    }
    
}

export default CloudStorageFirestoreHelper
