const radians = function(degrees: number): number {
    return degrees * (Math.PI / 180);
};

function getIntersections(A: [number, number], rA: number, B: [number, number], rB: number): [number, number][] | null {
    const [x0, y0] = A;
    const [x1, y1] = B;

    const d = Math.sqrt((x1 - x0) ** 2 + (y1 - y0) ** 2);

    if (d > rA + rB) {
        return null;
    }

    if (d < Math.abs(rA - rB)) {
        return null;
    }

    if (d === 0 && rA === rB) {
        return null;
    } else {
        const a = (rA ** 2 - rB ** 2 + d ** 2) / (2 * d);
        const h = Math.sqrt(rA ** 2 - a ** 2);
        const x2 = x0 + a * (x1 - x0) / d;
        const y2 = y0 + a * (y1 - y0) / d;
        const x3 = x2 + h * (y1 - y0) / d;
        const y3 = y2 - h * (x1 - x0) / d;

        const x4 = x2 - h * (y1 - y0) / d;
        const y4 = y2 + h * (x1 - x0) / d;

        return [[x3, y3], [x4, y4]];
    }
}

function convertPoint(Point: [number, number], Lat: number, Long: number): any {
    return {
        lat:Point[1] / 110.574 + Lat,
        lng:Point[0] / (111.320 * Math.cos(radians(Lat))) + Long
    };
}

function chooseTriangle(long: number, lat: number, MinSegmentSize: number, TotalPerimeter: number): any {
    const AB = TotalPerimeter / 3;
    const AC = TotalPerimeter / 3;
    const CB = TotalPerimeter / 3;

    const segmentSize = [AB, AC, CB];
    segmentSize.sort(() => Math.random() - 0.5);

    const initialAngle = Math.random() * 360;
    const A: [number, number] = [0, 0];
    const B: [number, number] = [
        Math.cos(radians(initialAngle)) * segmentSize[0],
        Math.sin(radians(initialAngle)) * segmentSize[0]
    ];

    const circleIntersections = getIntersections(A, segmentSize[2], B, segmentSize[1]);

    if (circleIntersections === null) {
        throw new Error("Inconsistent triangle");
    }

    const C = circleIntersections[Math.floor(Math.random() * circleIntersections.length)];

    return [convertPoint(A, lat, long), convertPoint(B, lat, long), convertPoint(C, lat, long)];
}


export const generateRandomPath = (coord:any, km:number=1) => {
  if (!coord) {
        return null;
  }
    return chooseTriangle(coord.lng, coord.lat, 0.5, Math.random() * 1 + km)
}

export const getCenter=(points:any) =>{
  let sumLat = 0;
  let sumLng = 0;

  for (const point of points) {
    sumLat += point.lat;
    sumLng += point.lng;
  }

  return {lat: sumLat / points.length, lng:sumLng / points.length};
}

export const calculateElevationGain=(elevations:any) =>{
    let totalGain = 0;
    let totalLoss = 0;
  
    for (let i = 1; i < elevations.length; i++) {
      const diff = elevations[i] - elevations[i - 1];
      if (diff > 0) {
        totalGain += diff;
      } else {
        totalLoss += Math.abs(diff);
      }
    }
  
    return { totalGainCalc:totalGain, totalLoss };
  }

  export const easyThreshold = 150; // en mètres
export const mediumThreshold = 250; // en mètres

export const  getRandomIndices=(arrayLength:number, quantity:number=3, minDistance:number=10)=> {
    const indices:any = [];
    let totalTryOuts = 0;
    while (indices.length < quantity && totalTryOuts < 25) {
      const randomIndex = Math.floor(Math.random() * arrayLength);
  
      // Vérifier que le nouvel indice est suffisamment éloigné des autres
      const isTooClose = indices.some((index:any) => Math.abs(index - randomIndex) < minDistance);
      
      if (!isTooClose) {
        indices.push(randomIndex);
      }
      totalTryOuts++;
    }
  
    return indices;
  }

export const getRoute = async(coord:any, user:any, getElevation:boolean=false)=>{
  const options = {
    method: "POST",
    headers: {
        "Content-type": "application/json",
        'Authorization': "Bearer " + await user?.getIdToken()
    },
    body: JSON.stringify({ coord: coord, elevation: getElevation })
  }
  const response = await fetch(`/api/get_route`, options);

  try {
    
    const data = await response.json()
    const coords = data.routes[0].geometry.coordinates
    if ( !coords || !coords.length ){
      throw new Error("No coordinates")
    }
    const distance = data.routes[0].distance
    const duration= data.routes[0].duration
    const legs = data.routes[0].legs

    const elevation = data.elevation
    const { totalGainCalc } = calculateElevationGain(data.elevation);
    let difficulty = 'easy';
    if (totalGainCalc < easyThreshold) {
      difficulty = 'easy'
    } else if (totalGainCalc < mediumThreshold) {
      difficulty = 'medium'
    } else {
      difficulty = 'hard'
    }

    return {coords, distance, legs, duration, elevation, difficulty}
  }catch(e){      
      if ( process.env.NODE_ENV==="development" ) {
          console.log("getRoute", e)
      }    
  }
}