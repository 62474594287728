import { ContentPriceType } from "../context/payment/type/contentPriceType";

export const ContentPriceList :ContentPriceType[] = [
  /*  {
        id: 'food100',        
        type: 'payment',
        url: '/images/cupcake.jpg',
        bg: '#ce316b',
        color:'white',
        price: 0.49,
        providerId: {
            stripe: process.env.NEXT_PUBLIC_STRIPE_CUPCAKE_100
        }
    },    */
    {
        id: 'food500',        
        type: 'payment',
        url: '/images/cupcake.jpg',
        bg: '#ce316b',
        color:'white',
        price: 1.99,
        providerId: {
            stripe: process.env.NEXT_PUBLIC_STRIPE_CUPCAKE_500,
            reactNative: 'cupcakes_500'
        }
    },    
    {
        id: 'food1000',        
        type: 'payment',
        url: '/images/cupcake.jpg',
        bg: '#ce316b',
        color:'white',
        price: 3.99,
        providerId: {
            stripe: process.env.NEXT_PUBLIC_STRIPE_CUPCAKE_1000,
            reactNative: 'cupcakes_1000'
        }
    },   
    {
        id: 'food5000',        
        type: 'payment',
        url: '/images/cupcake.jpg',
        bg: '#ce316b',
        color:'white',
        price: 14.99,
        providerId: {
            stripe: process.env.NEXT_PUBLIC_STRIPE_CUPCAKE_5000,
            reactNative: 'cupcakes_5000'
        }
    },    
    {
        id: 'zone1',        
        type: 'payment',
        url: '/images/zone.jpg',
        bg: '#199b23',
        color:'white',
        price: 200,
        providerId: {
            cupcake: true
        }
    },    
    {
        id: 'feather',        
        type: 'payment',
        url: '/images/feather.jpg',
        bg: '#fdaa00',
        color:'black',
        price: 400,
        providerId: {
            cupcake: true
        },
        blocked: "feather",
        description: true
    },   
    {
        id: 'eggNormal',        
        type: 'payment',
        url: '/images/eggs_NORMAL.jpg',
        bg: '#7f21fa',
        color:'white',
        price: 50,
        providerId: {
            cupcake: true
        }
    },  
    {
        id: 'eggAquatic',        
        type: 'payment',
        url: '/images/eggs_AQUATIC.jpg',
        bg: '#7f21fa',
        color:'white',
        price: 75,
        providerId: {
            cupcake: true
        }
    },  
    {
        id: 'eggFlying',        
        type: 'payment',
        url: '/images/eggs_FLYING.jpg',
        bg: '#7f21fa',
        color:'white',
        price: 100,
        providerId: {
            cupcake: true
        },
        unblocked: "feather"
    },  
   /* {
        id: 'eggLegendary',        
        type: 'payment',
        url: '/images/eggs_LEGENDARY.jpg',
        bg: '#7f21fa',
        color:'white',
        price: 100,
        providerId: {
            cupcake: true
        }
    },  
    {
        id: 'grimlak',        
        type: 'payment',
        url: '/images/gooblies/grimlak.jpg',
        bg:"#eba628",
        color:'black',
        price: 100,
        providerId: {
            cupcake: true
        }
    },    
    {
        id: 'dimzer',        
        type: 'payment',
        url: '/images/gooblies/dimzer.jpg',
        bg:"#eba628",
        color:'black',
        price: 100,
        providerId: {
            cupcake: true
        }
    }, 
    {
        id: 'gromol',        
        type: 'payment',
        url: '/images/gooblies/gromol.jpg',
        bg:"#eba628",
        color:'black',
        price: 100,
        providerId: {
            cupcake: true
        }
    },
    {
        id: 'rostok',        
        type: 'payment',
        url: '/images/gooblies/rostok.jpg',
        bg:"#eba628",
        color:'black',
        price: 100,
        providerId: {
            cupcake: true
        }
    },
    {
        id: 'splork',        
        type: 'payment',
        url: '/images/gooblies/splork.jpg',
        bg:"#eba628",
        color:'black',
        price: 100,
        providerId: {
            cupcake: true
        }
    },
    {
        id: 'bozor',        
        type: 'payment',
        url: '/images/gooblies/bozor.jpg',
        bg:"#eba628",
        color:'black',
        price: 100,
        providerId: {
            cupcake: true
        }
    },
    {
        id: 'crookzy',        
        type: 'payment',
        url: '/images/gooblies/crookzy.jpg',
        bg:"#eba628",
        color:'black',
        price: 100,
        providerId: {
            cupcake: true
        }
    },
    {
        id: 'gary',        
        type: 'payment',
        url: '/images/gooblies/gary.jpg',
        bg:"#eba628",
        color:'black',
        price: 100,
        providerId: {
            cupcake: true
        }
    },
    {
        id: 'gilbert',        
        type: 'payment',
        url: '/images/gooblies/gilbert.jpg',
        bg:"#eba628",
        color:'black',
        price: 100,
        providerId: {
            cupcake: true
        }
    },
    {
        id: 'slobzer',        
        type: 'payment',
        url: '/images/gooblies/slobzer.jpg',
        bg:"#eba628",
        color:'black',
        price: 100,
        providerId: {
            cupcake: true
        }
    },
    {
        id: 'radar',        
        type: 'payment',
        price: 2.99,
        providerId: {
            stripe: 'price_test'
        }
    },  
    {
        id: 'premium',        
        type: 'subscription',
        price: 4.99,
        providerId: {
            stripe: 'price_test'
        }
    },    
    {
        id: 'tourism',        
        type: 'subscription',
        price: 49.99,
        providerId: {
            stripe: 'price_test'
        }
    },  
    {
        id: 'tourismGold',        
        type: 'subscription',
        price: 249.99,
        providerId: {
            stripe: 'price_test'
        }
    },   */
] 