import { getLevelName } from "@/app/helpers/levelConversionHelper";
import { UserStateType } from "@/app/types/userStateType";
import { ReactElement } from "react";
import { v4 } from "uuid";

export const obtainMonster=(add:any, t:any, action: ReactElement|undefined, character:string="")=>{
    add(
        "obtainMonster",
        t("discussion"+character+".obtainMonster"), 
        "message", 
        "unread",
        Date.now() + 2000,
        action
    )
}

export const explainShopping=(add:any, t:any, character:string="")=>{
    add(
        "explainShopping",
        t("discussion"+character+".explainShopping"), 
        "message", 
        "unread",
        Date.now() + 2000
    )
}

export const firstHello=(add:any, t:any, character:string="")=>{
    add(
        null,
        t("discussion"+character+".firstHello"), 
        "message", 
        "unread",
        Date.now() + 2000
    );      
}

export const firstHelloInAppBrowsing=(add:any, t:any, action: ReactElement|undefined, character:string="")=>{
    add(
        "firstHelloInAppBrowsing",
        t("discussion.firstHelloInAppBrowsing"), 
        "message", 
        "unread",
        Date.now(),
        action
    );      
}

export const otherHello=(add:any, t:any, action?: ReactElement|undefined, character:string="")=>{
    add(
        "otherHello",
        t("discussion"+character+".otherHello"), 
        "message", 
        "unread",
        Date.now() + 2000,
        action
    );      
}

export const rainingHello=(add:any, t:any, action?: ReactElement|undefined, character:string="")=>{
    add(
        "otherHello",
        t("discussion"+character+".rainingHello"), 
        "message", 
        "unread",
        Date.now() + 2000,
        action
    );      
}

export const startGameMessage=(add:any, t:any, character:string="")=>{
    add(
        null,
        t("discussion"+character+".startGame"), 
        "message", 
        "unread",
        Date.now() 
    );      
}

export const startGameQuestMessage=(add:any, t:any, character:string="")=>{
    add(
        null,
        t("discussion"+character+".startGameQuest"), 
        "message", 
        "unread",
        Date.now() 
    );      
}

export const explainChase= (add:any, t:any, character:string="") => {
    add(
        "explainChase",
        t("discussion"+character+".explainChase"), 
        "message", 
        "unread",
        Date.now() + 2000
    )
}

export const explainEggs= (add:any, t:any, character:string="") => {
    add(
        "explainEggs",
        t("discussion"+character+".explainEggs"), 
        "message", 
        "unread",
        Date.now() + 2000
    )
}

export const explainHowToFeed =( add:any, t:any, character:string="") => {
    add(
        "howToFeed",
        t("discussion"+character+".explainHowToFeed"), 
        "message", 
        "unread",
        Date.now() + 2000
    )
}

export const obtainFood=(add:any, t:any, action: ReactElement|undefined, character:string="")=>{
    add(
        "obtainFood",
        t("discussion"+character+".obtainFoodMessage"), 
        "message", 
        "unread",
        Date.now() + 2000,
        action
    )
}

export const congratsFeed =(add:any, t:any, character:string="")=>{
    add(
        "congratsFeed",
        t("discussion"+character+".congratsFeed"), 
        "message", 
        "unread",
        Date.now() + 2000
    )
}

export const creatureCloseMessage=(add:any, t:any, character:string="")=>{
    const random = Math.floor(Math.random() * 4)+1;
    add(
        null,
        t("discussion"+character+".creatureClose"+random), 
        "message", 
        "unread",
        Date.now() 
    );      
}

export const captureCreatureMessage=(add:any, t:any, character:string="")=>{
    const random = Math.floor(Math.random() * 4)+1;
    add(
        null,
        t("discussion"+character+".captureCreature"+random), 
        "message", 
        "unread",
        Date.now() 
    );      
}

export const inLeaderboardMessage=(add:any, t:any, character:string="")=>{
    add(
        null,
        t("discussion"+character+".inLeaderboard"), 
        "message", 
        "unread",
        Date.now() 
    );      
}

export const finishGameNotConnectedMessage=(add:any, t:any, character:string="")=>{
    add(
        null,
        t("discussion"+character+".finishGameNotConnected"), 
        "message", 
        "unread",
        Date.now() 
    );      
}

export const loosehGameMessage=(add:any, t:any, character:string="")=>{
    add(
        null,
        t("discussion"+character+".looseGame"), 
        "message", 
        "unread",
        Date.now() 
    );      
}

export const doubleCardFriendsMessage=(add:any, t:any, character:string="")=>{
    add(
        null,
        t("discussion"+character+".doubleCardFriendsMessage"), 
        "message", 
        "unread",
        Date.now() 
    );      
}

export const newFriendMessage=(add:any, t:any, character:string="")=>{
    add(
        null,
        t("discussion"+character+".Trop bien! Tu as un nouvel ami."), 
        "message", 
        "unread",
        Date.now() 
    );      
}

export const firstEggMessage=(add:any, t:any, character:string="")=>{
    add(
        "newEgg",
        t("discussion"+character+".firstEgg"), 
        "message", 
        "unread",
        Date.now() 
    );      
}

export const firstFeatherMessage=(add:any, t:any, character:string="")=>{
    add(
        "newFeather",
        t("discussion"+character+".firstFeather"), 
        "message", 
        "unread",
        Date.now() 
    );      
}

export const newLevelMessage=(add:any, t:any, userState:UserStateType, character:string="")=>{
    add(
        "changeLevel",
        t("discussion"+character+".newLevel",{level:getLevelName(userState?.points || 0)}), 
        "message", 
        "unread",
        Date.now() 
    );      
}

export const fartMessage=(add:any, t:any, rand:number, character:string="")=>{
    add(
        "fart"+v4(),
        t("discussion"+character+".fart0"+rand), 
        "message", 
        "unread",
        Date.now() + 3000
    );      
}

export const newBadgeMessage=(add:any, t:any, badge: any, isFirstMessage:boolean, character:string="")=>{
    const message = !isFirstMessage? " "+t("discussion"+character+".newBadgeFirstMessage") : "" 
    add(
        "newBadge"+v4(),
        t("discussion"+character+".newBadge",{badge:t("badges."+badge.name)}) + message, 
        "message", 
        "unread",
        null
    );      
}