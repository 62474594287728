import { createContext, useContext, useEffect, useRef, useState } from 'react';
import { GlobalDataType } from '../../types/globalDataType';
import { useRouter } from 'next/router';
import { NotificationContext } from '../notification/notificationContext';
import { isDesktop } from 'react-device-detect'
import { calculateBearing, getDistance, getMinimalRotation, smoothBearing } from '@/app/helpers/mapHelper';
import { WebviewType } from '@/app/types/webviewType';
import { AuthenticationContext } from '../authentication/authenticationContext';
import { useTranslation } from 'next-i18next';

export const GlobalDataContext = createContext({} as GlobalDataType);

export const GlobalDataProvider = (props:any) => {

    const router = useRouter();
    const { language, categoryId, creatureId, chaseId, questId } = router.query;

    const [languageState, setLanguageState] = useState(language as "fr"|"en"|"de"|"nl")
    const [categoryIdState, setCategoryIdState] = useState(parseInt(categoryId as string))
    const [creatureIdState, setCreatureIdState] = useState<string>(creatureId as string)
    const [chaseIdState, setChaseIdState] = useState<string>(chaseId as string)
    const [questIdState, setQuestIdState] = useState<string>(questId as string)
    const [browserActive, setBrowserActive] = useState(true)
    const [isLeftSide, setIsLeftSide] = useState(false)
    const [isRightSide, setIsRightSide] = useState(false)
    const [showLeftSide, setShowLeftSide] = useState(false)
    const [showRightSide, setShowRightSide] = useState(false)
    const [isBrowsingFromGame, setIsBrowsingFromGame] = useState(false)
    const [location, setLocation] = useState<google.maps.LatLngLiteral|undefined>();
    const [bearing, setBearing] = useState<number>(0);
    const [firstLocation, setFirstLocation] = useState<google.maps.LatLngLiteral|undefined>();
    const [isInWebview, setIsInWebview] = useState(false)
    const watchPositionId = useRef<number | null>(null)
    const [distanceLocation, setDistanceLocation] = useState<number>(0)
    const [isRaining, setIsRaining] = useState(false)
    const [isSnowing, setIsSnowing] = useState(false)
    const [allowNaturalBearing, setAllowNaturalBearing] = useState(false)
    const [qrCodeScanned, setQrCodeScanned] = useState<string|false>(false)
    const [imageBase64, setImageBase64] = useState<string|null>(null)
    const [pushToken, setPushToken] = useState<string|null>(null)
    const [accuracy, setAccuracy] = useState<number>(0)

    const [webviewPurchase, setWebviewPurchase] = useState<any|null>(null)
    const [webviewTransactionSuccess, setWebviewTransactionSuccess] = useState<boolean|null>(null)
    const [webviewTransactionMessage, setWebviewTransactionMessage] = useState<string|null>(null)
    const [webviewPending, setWebviewPending] = useState<boolean>(false)
    const [inAppBrowsing, setInAppBrowsing] = useState(false)

    const { t, i18n } = useTranslation();
    const [isTranslationsLoaded, setTranslationsLoaded] = useState(false);

    useEffect(() => {
        setLanguageState(language as "fr"|"en"|"de"|"nl")   
    },[language])

    let bearings:any = [];

    const getSmoothedBearing=(newBearing:number) =>{
        bearings.push(newBearing);
        if (bearings.length > 5) bearings.shift(); // Garder seulement les 5 derniers bearings

        const sum = bearings.reduce((acc:any, b:any) => acc + b, 0);
        return sum / bearings.length; // Retourne la moyenne des bearings
    }

    useEffect(()=>{
        if (navigator && (navigator.userAgent.match(/FBAN|FBAV|Instagram|Messenger/i))) {
            setInAppBrowsing(true);
          }
    },[])

    // Detect when translations are loaded
    useEffect(() => {
        if (i18n.isInitialized) {
        setTranslationsLoaded(true);
        } else {
        i18n.on('initialized', () => {
            setTranslationsLoaded(true);
        });
        }
    }, [i18n]);

    const {
        setVoiceTalking
    } = useContext(NotificationContext)

    const {
        user,
        isAuthLoading,
        setGoogleAuthToken
    } = useContext(AuthenticationContext)

    const userRef = useRef<any>(null)

    useEffect(() => {
        userRef.current = user;
      }, [user]); 

    useEffect(() => {
        const storedLocation = localStorage.getItem('lastLocation');
        if (storedLocation) {
            try {
                setLocation(JSON.parse(storedLocation));
            } catch (e) {
                console.error('Error parsing stored location:', e); 
            }
        }        
      }, []);

    useEffect(()=>{
        if( location && !firstLocation) {
            localStorage.setItem('lastLocation', JSON.stringify(location));
            setFirstLocation(location)
        }
    },[location])


    const locationRef = useRef(location);

    useEffect(() => {
        locationRef.current = location;
    }, [location]);

/*
    useEffect(() => {
        const handleOrientation = (event:any) => {
            setAllowNaturalBearing(true)
            const compassHeading = event.webkitCompassHeading || Math.abs(event.alpha - 360); 
            const alpha = event.alpha; 
            
            setBearing(getMinimalRotation(bearing,getSmoothedBearing(compassHeading)));
        };

        window.addEventListener('deviceorientation', handleOrientation);

        return () => {
        window.removeEventListener('deviceorientation', handleOrientation);
        };
    }, []);
*/
    useEffect(()=>{
        if ( watchPositionId.current ) {
            if ( process.env.NODE_ENV==="development" ) {
                console.log("WTFRETURNGEOLOC STOP", watchPositionId.current)
            }
            navigator.geolocation.clearWatch(watchPositionId.current);
        } 
        try {
            if ( navigator.permissions && navigator.permissions.query && !isInWebview) {
                //try permissions APIs first
                navigator.permissions.query({ name: 'geolocation' }).then(function(result) {
                    // Will return ['granted', 'prompt', 'denied']
                    const permission = result.state;
                    
                    if ( permission === 'granted' || permission === 'prompt' ) {
                        watchPositionId.current = navigator.geolocation.watchPosition(({ coords }) => 
                            {
                                
                                if ( process.env.NODE_ENV==="development" ) {
                                    console.log("geolocation watch", coords)
                                }
                                const { latitude, longitude, accuracy, heading } = coords;  
                                setAccuracy(accuracy)
                                if ( accuracy < 50 ) {
                                    //setBearing(heading || 0);
                                }
                                if ( locationRef?.current ) {
                                    if (locationRef?.current && latitude && longitude) {
                                        const distance = getDistance(
                                            {
                                                latitude: latitude,
                                                longitude: longitude
                                            },
                                            {
                                                latitude: locationRef?.current?.lat,
                                                longitude: locationRef?.current?.lng
                                            },
                                            1
                                        )
                                        setDistanceLocation(distance)
                                    }                                    
                                }              
                                setLocation({ lat: latitude, lng: longitude });
                            },
                            (e) => {
                                if ( process.env.NODE_ENV==="development" ) {
                                    console.log("geolocation error", e)
                                }
                            },
                            { enableHighAccuracy: true,  maximumAge: 0, timeout: 5000}
                        );
                    }
                });
              }
            }catch(e) {
                if ( process.env.NODE_ENV==="development" ) {
                    console.log("geolocation error", e)
                }
            }
        //}

        return ()=>{
            if ( process.env.NODE_ENV==="development" ) {
                console.log("WTFRETURNGEOLOC", watchPositionId.current)
            }
            if ( watchPositionId.current) {
                navigator.geolocation.clearWatch(watchPositionId.current);
            }
        }
    },[])

    useEffect(()=>{
        if ( showLeftSide && showRightSide){
            setShowRightSide(false)
        }
    },[showLeftSide,showRightSide])

    useEffect(()=>{
        const routeChange = (url:string) => {
            setVoiceTalking(false)
            setShowLeftSide(false)
            setIsBrowsingFromGame(true)
        };
        router.events.on('routeChangeStart', routeChange);

        return () => {
            router.events.off('routeChangeStart', routeChange);
        }
    },[])

    useEffect(()=>{
        setLanguageState(language as "fr"|"en"|"de"|"nl")
    },[language])
    
    useEffect(()=>{
        setCategoryIdState(parseInt(categoryId as string))
    }, [categoryId])

    useEffect(()=>{
        setCreatureIdState(creatureId as string)
    }, [creatureId])
    
    useEffect(()=>{
        setChaseIdState(chaseId as string)
    }, [chaseId])

    useEffect(()=>{
        setQuestIdState(questId as string)
    },[questId])

    useEffect(() => {
       const handleFocus = () => {
            setBrowserActive(true);
        };

        const handleBlur = () => {
            setBrowserActive(false);
        };

        window.addEventListener('focus', handleFocus);
        window.addEventListener('blur', handleBlur);

        // Clean up
        return () => {
            window.removeEventListener('focus', handleFocus);
            window.removeEventListener('blur', handleBlur);
        };
    }, []);


    // TODO: gérer le message de la webview
    useEffect(() => {
        const handleMessage = (event:any) => {
            try {
                const message = JSON.parse(event.data);
                if (!message || message.domain !=="GOOBLIES")
                  return;
          
                if (message && message.action =="inWebview") {
                    setIsInWebview(true)              
                }
                
                if ( message && message.action =="pushToken") {
                    setPushToken(message.payload.token)
                }

                if ( message && message.action =="qrCodeScanned"){
                    setQrCodeScanned(message.payload)
                    //alert("QR CODE SCANNED" + JSON.stringify(message.payload))
                }

                if ( message && message.action =="cameraScreenshot"){
                    setImageBase64(message.payload)
                    //alert("QR CODE SCANNED" + JSON.stringify(message.payload))
                }

                if ( message && message.action =="purchaseRequest" && message.payload){
                    if ( process.env.NODE_ENV==="development" ) {
                        console.log("PURCHASE REQUEST", message.payload)
                    }
                    if ( !message.payload.error) {
                        isValidPurchase(message.payload.purchase.productId, message.payload.purchase.purchaseToken).then((valid:boolean)=>{
                            if (valid ) {
                                setWebviewPurchase(message.payload.purchase)
                                setWebviewTransactionSuccess(true)
                                setWebviewTransactionMessage(message.payload.error)
                                setWebviewPending(false)
                                const data:WebviewType= {
                                    domain: "GOOBLIES",
                                    action: "purchaseRequestValidation",
                                    payload: {
                                        transactionId: message.payload.purchase.transactionId,
                                        success: true,
                                        message: "Purchase success"
                                    }
                                }
                                window.ReactNativeWebView.postMessage(JSON.stringify(data));
                            }else {
                                setWebviewPurchase(message.payload.purchase)
                                setWebviewTransactionSuccess(false)
                                setWebviewTransactionMessage(message.payload.error)
                                setWebviewPending(false)
                                const data:WebviewType= {
                                    domain: "GOOBLIES",
                                    action: "purchaseRequestValidation",
                                    payload: {
                                        transactionId: message.payload.purchase.transactionId,
                                        success: false,
                                        message: "Purchase error"
                                    }
                                }
                                window.ReactNativeWebView.postMessage(JSON.stringify(data));
                            }
                        }).catch((e)=>{
                            setWebviewPending(false)
                            setWebviewTransactionSuccess(false)
                        })
                        
                    }else{
                        setWebviewPurchase(message.payload.purchase)
                        setWebviewTransactionSuccess(false)
                        setWebviewTransactionMessage(message.payload.error)
                        setWebviewPending(false)
                    }
                    //alert("QR CODE SCANNED" + JSON.stringify(message.payload))
                }

                if ( message && message.action=="googleAuthToken") {
                    if ( message.payload && message.payload.token) {
                        if ( process.env.NODE_ENV==="development" ) {
                            console.log("GOOGLE AUTH TOKEN", message.payload.token)
                        }
                        setGoogleAuthToken(message.payload.token)
                        //setGoogleAuthToken(message.payload.token)
                    }
                }
                if ( message && message.action=="location" && message.payload) {
                    if ( locationRef?.current ) {
                        if (locationRef?.current && message.payload.lat && message.payload.lng) {
                            const distance = getDistance(
                                {
                                    latitude: message.payload.lat,
                                    longitude: message.payload.lng
                                },
                                {
                                    latitude: locationRef?.current?.lat,
                                    longitude: locationRef?.current?.lng
                                },
                                1
                            )
                            setDistanceLocation(distance)
                        }                                    
                    } 
                    setLocation(message.payload)
                }

            } catch (e) {
                console.error('Error parsing message data:', e);
            }   
        };
    
        document.addEventListener("message", handleMessage);
    
        return () => {
            document.removeEventListener("message", handleMessage);
        };
  }, []);


    useEffect(() => {
        if (window.ReactNativeWebView) {
            const data:WebviewType= {
                domain: "GOOBLIES",
                action: "loaded",
                payload: {
                    language: languageState,
                }
            }
            window.ReactNativeWebView.postMessage(JSON.stringify(data));
        }
    }, [languageState]);   

    useEffect(()=>{
        if (user && !isAuthLoading && firstLocation ) {
            getWeather()
        }
    },[user,isAuthLoading, firstLocation])

    const isValidPurchase = async (productId:string, purchaseToken:string) => {
        const options = {
            method: "POST",
            headers: {
                "Content-type": "application/json",
                'Authorization': "Bearer " + await userRef.current?.getIdToken()
            },
            body: JSON.stringify({ 
                productId: productId,
                purchaseToken: purchaseToken
             })
          }
          const response = await fetch(`/api/purchase`, options);
        
          try {
            
            const data = await response.json()
            if ( data && data.success){
                return true
            }
          } catch (e) {

          }
          return false
    }

    const getWeather = async ()=>{
        let cacheResponse
        // CALL VIA BACK BUT DONT WORK
        const BASE_URL = process.env.NEXT_PUBLIC_API_URL_V2
  
        const headers = {
            "Content-type": "application/json",
            'Authorization' : "Bearer " + await user?.getIdToken(),
        };
  
        const requestBody = {
            lat: location?.lat,
            lng: location?.lng
        };
  
        const response = await fetch(`${BASE_URL}weather`, {
            method: "POST",
            headers: headers,
            body: JSON.stringify(requestBody)
        });
  

        if (response.status === 200 ) {
            const weather = await response.json();
            if ( process.env.NODE_ENV==="development" ) {
                console.log("WEATHER", weather)
            }
            if (weather && weather.data && weather.data.weather) {
                const isRaining = weather.data.weather.some((condition:any) => 
                  condition.main.toLowerCase().includes('rain')
                );
                setIsRaining(isRaining)
                const isSnowing= weather.data.weather.some((condition:any) => 
                    condition.main.toLowerCase().includes('snow')
                  );
                  setIsSnowing(isSnowing)
            }else{
                setIsRaining(false)
                setIsSnowing(false)
            }

        }else{
            setIsRaining(false)
            setIsSnowing(false)
        }
        return false
    }

    return (
        <GlobalDataContext.Provider value={{
            language: languageState,
            categoryId: categoryIdState,
            creatureId: creatureIdState,
            chaseId: chaseIdState,
            questId: questIdState,
            browserActive,
            showLeftSide,
            showRightSide,
            setShowLeftSide,
            setIsLeftSide,
            setIsRightSide,
            setShowRightSide,
            isBrowsingFromGame,
            location,
            bearing,
            firstLocation,
            setFirstLocation,
            isDesktop,
            distanceLocation,
            isRaining,
            isSnowing,
            isTranslationsLoaded,
            qrCodeScanned,
            setQrCodeScanned,
            pushToken,
            imageBase64,
            isInWebview,
            webviewPurchase,
            webviewTransactionSuccess,
            webviewTransactionMessage,
            webviewPending,
            setWebviewPending,
            setWebviewTransactionSuccess,
            inAppBrowsing
        }}>           
            {props.children}          
        </GlobalDataContext.Provider>
    )
}