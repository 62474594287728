import { useContext, useEffect, useReducer, useRef, useState } from "react";
import { AuthenticationContext } from "../../authentication/authenticationContext";
import { FriendRequestType } from "../types/friendRequestType";
import { UserStateType } from "@/app/types/userStateType";
import { FriendRequestWithUserType } from "../types/friendRequestWithUserType";
import { GlobalDataUserContext } from "../../global/globalDataUserContext";
import { GlobalDataContext } from "../../global/globalDataContext";
import { Unsubscribe } from "firebase/auth";

export const useFriendRequests = () => {
    const initialState:{
        friendRequests: FriendRequestType[], 
        friendRequestsWithUserType: FriendRequestWithUserType[],
        allUseState: UserStateType[]
    } = {friendRequests: [], friendRequestsWithUserType:[], allUseState: []};
    const [isLoading, setIsLoading] = useState<boolean>(true);
    const {user, isAuthLoading, logOut, cloudStorage, isSigning} = useContext(AuthenticationContext)
    const {userInfo, userState, isGlobalDataUserLoading, showFriends} = useContext(GlobalDataUserContext)
    const {browserActive} = useContext(GlobalDataContext)
    type Action =
        | { type: 'ADD_FROM_CLOUD'; payload: FriendRequestType[] }
        | { type: 'UPDATE_FROM_CLOUD'; payload: FriendRequestType[] }
        | { type: 'SET_FRIEND_REQUESTS_WITH_USER_TYPE'; payload: FriendRequestWithUserType[] }
        | { type: 'ADD_ALL_USERSTATE'; payload: UserStateType[] }
        | { type: 'UPDATE_ALL_USERSTATE'; payload: UserStateType[] }
        | { type: 'DELETE'; payload: FriendRequestType[] }
        | { type: 'EMPTY' }
        | { type: 'EMPTY_FRIENDS_REQUESTS_WITH_USER_TYPE' }


    const reducer= (state:any, action:Action) => {
        switch (action.type) {
        case 'ADD_FROM_CLOUD':
            return {
                ...state,
                friendRequests: [
                    ...state.friendRequests,
                    ...action.payload.map((item: FriendRequestType) => ({
                        ...item,
                        isSynchronized: true,
                    }))
                ]
            };
        case 'UPDATE_FROM_CLOUD':
            return {
                ...state,
                friendRequests: state.friendRequests.map((item: FriendRequestType) => {
                    const updatedItem = action.payload.find((itemAction: FriendRequestType) => itemAction.id === item.id);
                    if (updatedItem) {
                        return { ...item, ...updatedItem, isSynchronized: true };
                    }
                    return item;
                })
            };
        case 'SET_FRIEND_REQUESTS_WITH_USER_TYPE':
            return {
                ...state,
                friendRequestsWithUserType: action.payload
            };
        case 'EMPTY_FRIENDS_REQUESTS_WITH_USER_TYPE':
            return {
                ...state,
                friendRequestsWithUserType: []
            };
        case 'ADD_ALL_USERSTATE':
                return {
                    ...state,
                    allUseState: [
                        ...state.allUseState,
                        ...action.payload
                    ]
                };
        case 'UPDATE_ALL_USERSTATE':
            return {
                ...state,
                allUseState: state.allUseState.map((item: UserStateType) => {
                    const updatedItem = action.payload.find((itemAction: UserStateType) => itemAction.userId === item.userId);
                    if (updatedItem) {
                        return { ...item, ...updatedItem };
                    }
                    return item;
                })
            };
        case 'DELETE':
            return {
                ...state,
                friendRequests: state.friendRequests.filter((item: FriendRequestType) => !action.payload.find((actionItem: FriendRequestType) => actionItem.id === item.id))
            };
        case 'EMPTY':
            return {
                friendRequests: [],
                friendRequestsWithUserType:[], 
                allUseState: []
            };

            default:
                return state;
        }
     }
    const [state, dispatch] = useReducer(reducer, initialState);

    const stateRef = useRef(state);

    useEffect(() => {
        stateRef.current = state;
    }, [state]);

    const emptyState=()=>{
        dispatch({
            type: 'EMPTY'})
    }

    useEffect(()=>{
        if(user && !isAuthLoading && cloudStorage){
            const unsubscribe = cloudStorage?.subscribeToFriendRequests(
                (friend, friendToRemove)=>{
                    if ( process.env.NODE_ENV==="development" ) {
                        console.log("subscribeToFriendRequests", friend)
                    }
                    if ( friend && friend.length>0 ) {
                        const itemsToAdd = friend?.filter(
                            (item:FriendRequestType) => !stateRef.current.friendRequests.some((stateItem: FriendRequestType) => stateItem.id === item.id)
                        ) || [];
                        if ( itemsToAdd && itemsToAdd.length > 0 ){
                            dispatch({
                                type: 'ADD_FROM_CLOUD', 
                                payload: itemsToAdd})
                        }

                        const updatedItemsState = friend
                        .filter(
                            (item:FriendRequestType) => stateRef.current.friendRequests.some((stateItem: FriendRequestType) => stateItem.id === item.id)
                        ) || []
                
                        if ( updatedItemsState && updatedItemsState.length > 0 ){
                            dispatch({
                                type: 'UPDATE_FROM_CLOUD', 
                                payload: updatedItemsState})
                        }

                        if ( friendToRemove && friendToRemove.length > 0 ){
                            dispatch({
                                type: 'DELETE', 
                                payload: friendToRemove})
                        }
                    }
                },
                setIsLoading
            )

            return () => {
                if ( unsubscribe ) { unsubscribe() }
            }
        }
    },[user, isAuthLoading, cloudStorage])

    useEffect(()=>{
        if ( !user && !isAuthLoading) {
            emptyState()
            setIsLoading(false)
        }
    },[user, isAuthLoading])

    useEffect(()=>{
        if (user && !isAuthLoading && !isGlobalDataUserLoading && cloudStorage) {
            if ( process.env.NODE_ENV==="development" ) {
                console.log("reenclence isGlobalDataUserLoading")
            }
            /*const me: FriendRequestWithUserType = {
                id: "0",
                senderId: user.uid,
                receiverId: user.uid,
                timestamp: 0,
                user: {
                    id: user.uid,
                    name: userInfo?.userName || user.displayName || '',
                    photoUrl: userInfo?.photoUrl || user.photoURL || '',
                    lastActivity: userInfo?.lastActivity || 0
                },
                state: {
                    userId: userState?.userId || '',
                    points: userState?.points || 0,
                    gooblies: userState?.gooblies || 0,
                    xp: userState?.xp || 0,
                    badges: userState?.badges || [],
                    kilometers: userState?.kilometers || 0,
                    food: userState?.food || 0,
                    lastActivity: userState?.lastActivity || 0
                },
                status: "approved",
                rejectedBy: ""
            };*/
    
            if (state.friendRequests?.length > 0) {
                dispatch({
                    type: 'EMPTY_FRIENDS_REQUESTS_WITH_USER_TYPE'
                })
                const friendIds = state.friendRequests
                    .filter((item: FriendRequestType) => item.status !== "rejected")
                    .map((item: FriendRequestType) => item.senderId === user.uid ? item.receiverId : item.senderId);
    
                if (friendIds.length) {
                    cloudStorage?.getUsersInfo(friendIds).then(result => {
                        if (process.env.NODE_ENV === "development") {
                            console.log("Fetched users info:", friendIds, result);
                        }
    
                        const friendRequestWithUserTmp = state.friendRequests.map((item: FriendRequestType) => {
                            const id = user.uid === item.receiverId ? item.senderId : item.receiverId;
                            const userInfo = result.find((res: any) => res.id === id);
    
                            if (userInfo) {
                                return {
                                    id: item.id,
                                    senderId: item.senderId,
                                    receiverId: item.receiverId,
                                    timestamp: item.timestamp,
                                    user: {
                                        id: userInfo.id,
                                        name: userInfo.userName,
                                        photoUrl: userInfo.photoUrl,
                                        lastActivity: userInfo.lastActivity
                                    },
                                    state: userInfo.state || {},
                                    status: item.status,
                                    rejectedBy: ""
                                };
                            }
                            return null;
                        }).filter(Boolean) as FriendRequestWithUserType[];
    
                        if (process.env.NODE_ENV === "development") {
                            console.log("Processed friend requests with user data:", friendRequestWithUserTmp);
                        }

                        dispatch({
                            type: 'SET_FRIEND_REQUESTS_WITH_USER_TYPE', 
                            payload: [...friendRequestWithUserTmp]})
                        setIsLoading(false);
                    });
                } else {
                    dispatch({
                        type: 'SET_FRIEND_REQUESTS_WITH_USER_TYPE', 
                        payload: []})
                    setIsLoading(false);
                }
            } else {
                dispatch({
                    type: 'SET_FRIEND_REQUESTS_WITH_USER_TYPE', 
                    payload: [ ]})
                setIsLoading(false);
            }
        } else {
            dispatch({
                type: 'SET_FRIEND_REQUESTS_WITH_USER_TYPE', 
                payload: []})
            setIsLoading(false);
        }
    },[state.friendRequests,user,isAuthLoading,isGlobalDataUserLoading,cloudStorage])

    const unsubscribeRef = useRef<Unsubscribe|null>(null);

    useEffect(() => {
        if (!state.friendRequests?.length || !cloudStorage || !user || isAuthLoading) return;
    
        //let unsubscribe = null
        if ( showFriends  ){
            const friendIds = [ ...state.friendRequests
                .filter((item: FriendRequestType) => item.status !== "rejected")
                .map((item: FriendRequestType) => item.senderId === user.uid ? item.receiverId : item.senderId)];
        
            if (process.env.NODE_ENV === "development") {
                console.log("WTH SNAPSHOT ZONE subscribeToAllUserState", friendIds);
            }
        
            unsubscribeRef.current = cloudStorage.subscribeToAllUserState(friendIds, state => {
                if (process.env.NODE_ENV === "development") {
                    console.log("WTH SNAPSHOT ZONE subscribeToAllUserState", state);
                }
                
                if (state && state.length > 0) {
                    const itemsToAdd = state.filter((item: UserStateType) => !stateRef.current.allUseState.some((stateItem: UserStateType) => stateItem.userId === item.userId));
                    if (itemsToAdd && itemsToAdd.length > 0) {
                        dispatch({
                            type: 'ADD_ALL_USERSTATE', 
                            payload: itemsToAdd
                        });
                    }
        
                    const updatedItemsState = state.filter((item: UserStateType) => stateRef.current.allUseState.some((stateItem: UserStateType) => stateItem.userId === item.userId));
                    if (updatedItemsState && updatedItemsState.length > 0) {
                        dispatch({
                            type: 'UPDATE_ALL_USERSTATE', 
                            payload: updatedItemsState
                        });
                    }
                }
            });
        }else{
            if (unsubscribeRef.current){ 
                unsubscribeRef.current()
            }
        }
        return () => {
            if (unsubscribeRef.current){ 
                unsubscribeRef.current()
            }
        }
    }, [state.friendRequests, cloudStorage, user, isAuthLoading, browserActive, showFriends]);

    useEffect(()=>{
        if ( logOut ){
            emptyState()
        }
    },[logOut])

    useEffect(()=>{
        if ( isSigning){
            emptyState()
        }
    },[isSigning])

    return {
        state,
        isLoading
    }
}