import { CharacterType } from "../context/notification/type/characterType";

export const AllCharactersConst: CharacterType[] = [
    {
        name: "Ana Hunter",
        translationKey: "",
        background: "/images/ana.jpeg",
        image: "/images/avatar.png",
        imageTalking: "/images/ana.webp",
        voiceId: "FvmvwvObRqIHojkEGh5N",
        details:[
            "Chasseuse de Gooblies",
            "Aventurière",
            "Intrépide",            
        ]
    },
    {
        name: "Christian",
        translationKey: "Christian",
        background: "/images/christian-background.jpeg",
        image: "/images/christian.jpeg",
        imageTalking: "/images/christian.webp",
        voiceId: "HTqq05aO6aXZv298TrPX",
        details:[
            "Professeur de Gooblies",
            "Archélogie Goobliestique",
            "Analyste",
        ]
    },
    {
        name: "Papitou",
        translationKey: "Papitou",
        background: "/images/papitou-background.jpeg",
        image: "/images/papitou-avatar.jpg",
        imageTalking: "/images/papitou.webp",
        voiceId: "TKA0t0wWjVvV0HV3H8Lv",
        details:[
            "Ancien collectionneur de Gooblies",
            "Compteur d'histoires",
            "Radoteur",
        ]
    }
]