import Script from 'next/script';
import type { AppProps } from 'next/app'
import { ChakraProvider, extendTheme } from '@chakra-ui/react'
import '@/app/styles/globals.css'
//import '@/app/styles/responsive.css'
import 'mapbox-gl/dist/mapbox-gl.css'
import { FirebaseProvider } from '@/app/context/firebase/firebaseContext'
import { AuthenticationProvider } from '@/app/context/authentication/authenticationContext'
import { appWithTranslation, UserConfig } from 'next-i18next'
import nextI18NextConfig from '../../next-i18next.config.js'
import 'regenerator-runtime/runtime'
import { PaymentProvider } from '@/app/context/payment/paymentContext'
import { useEffect } from 'react'
import { FriendsProvider } from '@/app/context/friends/friendsContext'
import { GlobalDataUserProvider } from '@/app/context/global/globalDataUserContext'
import { GlobalDataProvider } from '@/app/context/global/globalDataContext'
import { AnimatePresence } from 'framer-motion'
import { NotificationProvider } from '@/app/context/notification/notificationContext'

const theme = extendTheme({
  styles: {
    global: {
      "*:focus": {
        outline: "none !important",
        boxShadow: "none !important",
      },
      "*:active": {
        outline: "none !important",
        boxShadow: "none !important",
      },
      "*:hover": {
        outline: "none !important",
        boxShadow: "none !important",
      },
    },
  },
})

/*f (typeof window !== 'undefined') { // checks that we are client-side
  posthog.init(process.env.NEXT_PUBLIC_POSTHOG_KEY as string, {
    api_host: process.env.NEXT_PUBLIC_POSTHOG_HOST || 'https://app.posthog.com',
    loaded: (posthog) => {
      if (process.env.NODE_ENV === 'development') posthog.debug() // debug mode in development
    },
  })
}*/
const firebaseConfig = {
  apiKey: process.env.NEXT_PUBLIC_FIREBASE_API_KEY,
  authDomain: process.env.NEXT_PUBLIC_FIREBASE_AUTH_DOMAIN,
  projectId: process.env.NEXT_PUBLIC_FIREBASE_PROJECT_ID,
  storageBucket: process.env.NEXT_PUBLIC_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: process.env.NEXT_PUBLIC_FIREBASE_MESSAGING_SENDER_ID,
  appId: process.env.NEXT_PUBLIC_FIREBASE_APP_ID,
  measurementId: process.env.NEXT_PUBLIC_FIREBASE_MEASUREMENT_ID
}

const App = ({ Component, pageProps : { session, ...pageProps }}: AppProps) => {
  useEffect(()=>{

    const documentHeight = () => {
      const doc = document.documentElement
      doc.style.setProperty('--doc-height', `${window.innerHeight}px`)
    }
    window.addEventListener("resize", documentHeight)
    window.addEventListener('orientationchange', documentHeight);
    documentHeight()
/*
    if ("virtualKeyboard" in (navigator as any)) {
      console.log((navigator as any).virtualKeyboard.overlaysContent); // false
      (navigator as any).virtualKeyboard.overlaysContent = true; // Opt out of the automatic handling.
    }
    */
    return () => {
        window.removeEventListener('resize', documentHeight);
        window.removeEventListener('orientationchange', documentHeight);
    };

    
  },[])
  

  return (
    <>
    <ChakraProvider theme={theme}>
        <NotificationProvider>
          <FirebaseProvider
            firebaseConfig={firebaseConfig}
          >            
            <AuthenticationProvider
              firebaseConfig={firebaseConfig}
              >
                              
              <GlobalDataProvider>
                <GlobalDataUserProvider>
                  <FriendsProvider>  
                    <PaymentProvider>
                      {/*<AnimatePresence initial={false} mode={"wait"}>*/}
                        <Component {...pageProps} />
                      {/*</AnimatePresence>*/}
                    </PaymentProvider>
                  </FriendsProvider>
                </GlobalDataUserProvider>
              </GlobalDataProvider>
              
            </AuthenticationProvider>
          </FirebaseProvider>
          </NotificationProvider>
      </ChakraProvider>      
    </>
  )
}

export default appWithTranslation(App, nextI18NextConfig as UserConfig)
