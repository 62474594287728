import { CreatureCategoryConst } from "../const/creatureCategoryConst"
import { getCreatureDetailsHelper } from "./getCreatureDetailsHelper"

export const buildGameUrl=(language:string, categoryId:number, creatureId?:string)=>{
    let categoryName = ""
    let creatureName = ""

    if ( categoryId) {
        CreatureCategoryConst.map((category)=>{
            if ( category.id == categoryId ){
                categoryName = category.url
            }
        })
    }

    if (creatureId){
        const creature = getCreatureDetailsHelper(creatureId)
        if (creature){
            creatureName = creature.name.toLowerCase()            
        }
    }

    if ( categoryId && creatureId ){
        return "/"+(language??"en")+'/'+ String(categoryId) +'/'+ String(categoryName) +'/creature/' + String(creatureId) + "/" + String(creatureName)  
    }
    if ( categoryId ){
        return "/"+(language??"en")+'/'+ String(categoryId) +'/'+ String(categoryName)
    }
    return "/"+(language??"en")
}


export const buildEggUrl=(language:string, categoryId:number)=>{
    let categoryName = ""
    let creatureName = ""

    if ( categoryId) {
        CreatureCategoryConst.map((category)=>{
            if ( category.id == categoryId ){
                categoryName = category.url
            }
        })
    }

    if ( categoryId  ){
        return "/"+(language??"en")+'/'+ String(categoryId) +'/'+ String(categoryName) +'/eggs' 
    }
    return "/"+(language??"en")
}

export const buildChaseUrl=(language:string, categoryId:number, chaseId:string) => {
    if ( !categoryId ){
        categoryId = 1
    }
    let categoryName = ""

    if ( categoryId) {
        CreatureCategoryConst.map((category)=>{
            if ( category.id === categoryId ){
                categoryName = category.url
            }
        })
    }

    return "/"+(language??"en")+'/'+ String(categoryId) +'/'+ String(categoryName) +'/chase/' + String(chaseId)
}

export const buildArtifactUrl=(language:string) => {
    return "/"+(language??"en")+'/artifact'
}

export const buildShoppingUrl=(language:string) => {
    return "/"+(language??"en")+'/shopping'
}

export const buildZoneUrl=(language:string, zoneId:string) => {
    return "/"+(language??"en")+'/zone/'+ String(zoneId)
}

export const buildApiStripeCheckouUrl=(language:string)=>{
    return language+'/payment/checkout'
}

export const buildApiStripeValidateuUrl=(language:string)=>{
    return language+'/payment/validate'
}

export const buildQuestUrl=(language:string, categoryId:number, questId:string="0", sizePath?:string) => {
    if ( !categoryId ){
        categoryId = 1
    }
    let categoryName = ""

    if ( categoryId) {
        CreatureCategoryConst.map((category)=>{
            if ( category.id === categoryId ){
                categoryName = category.url
            }
        })
    }

    return "/"+(language??"en")+'/'+ String(categoryId) +'/'+ String(categoryName) +'/quest/' + String(questId) + (sizePath?"?sizePath=" + sizePath:"")
}